export const px2vw = (px) => {
  return px / 4.14;
};

export const designPx2RealPx = (px) => {
  const radio = window.innerWidth / 414;

  return px * radio;
};

export const isAndroid = () => /Android/i.test(navigator.userAgent);

export function insertAfter(newEl, targetEl) {
  const parentEl = targetEl.parentNode;

  if (parentEl.lastChild === targetEl) {
    parentEl.appendChild(newEl);
  } else {
    parentEl.insertBefore(newEl, targetEl.nextSibling);
  }
}
