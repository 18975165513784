import React, { useState } from 'react';
import classnames from 'classnames';
import SwipeRuler from '../SwipeRuler';
import findIndex from 'lodash-es/findIndex';
import { useMount } from 'react-use';
import './style.scss';

/**
 *
 * @param {array} times [{ label: '2017年', value: 2017, ellipsis: false }]
 * @param {string} active time 的 value
 * @param {function} onChange 参数是点击的 value
 */
export default function ({ times, onChange, active }) {
  const [initialSlide, setInitialSlide] = useState(0);
  const [beforeArr, setBeforeArr] = useState([]);
  const [activeArr, setActiveArr] = useState([]);
  const [afterArr, setAfterArr] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const handleActiveChange = (active) => {
    onChange(active);
  };
  useMount(() => {
    let from = findIndex(times, (item) => !item.ellipsis);
    let to = findIndex(times, (item) => item.ellipsis, from === -1 ? 0 : from);
    const activeIndex = times.filter((item) => !item.ellipsis).findIndex((time) => time.value === active);

    setInitialSlide(activeIndex);
    to = to === -1 ? times.length : to;
    setBeforeArr(times.slice(0, from === -1 ? times.length : from));
    setActiveArr(times.slice(from, to));
    setAfterArr(times.slice(to, times.length));
    setIsReady(true);
  });
  return (
    <div className="timeline">
      <ul>
        {beforeArr.map((time, index) => (
          <li key={index} className="ellipsis"></li>
        ))}
      </ul>
      {isReady && <SwipeRuler items={activeArr} initialSlide={initialSlide === -1 ? 0 : initialSlide} onChange={handleActiveChange} direction="vertical" />}
      <ul>
        {afterArr.map((time, index) => (
          <li key={index} className="ellipsis"></li>
        ))}
      </ul>
    </div>
  );
}
