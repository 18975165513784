import { client, gql } from './index';

export const getTask = async (id) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getQuiz($id: Int!) {
        getQuiz(id: $id) {
          id
          name
          externalId
          description {
            url
            type
            thumbnail
            videoSnapshot
            videoSnapshotThumbnail
          }
          questions {
            id
            externalId
            title
            type
            options {
              index
              content
              contentType
              url
            }
            description {
              url
              type
              videoSnapshot
            }
            answer
            ignoreAnswerOrder
            createdAt
            updatedAt
          }
        }
      }
    `,
    variables: {
      id,
    },
  });
  return data.getQuiz;
};

export const submitTask = async (body) => {
  const { data } = await client.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation submitTask($answer: [UserQuestionAnswerInput!]!, $seconds: Int!, $quizId: Int!) {
        completeQuiz(answer: $answer, seconds: $seconds, quizId: $quizId) {
          success
          points
        }
      }
    `,
    variables: {
      answer: body.answer,
      seconds: body.seconds,
      quizId: body.quizId,
    },
  });
  return data.completeQuiz;
};

export const getPuzzleTask = async (id) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getPuzzle($id: Int!) {
        getPuzzle(id: $id) {
          id
          candidates {
            index
            content
            contentType
            url
          }
          answer
          fakeModelImages
        }
      }
    `,
    variables: {
      id,
    },
  });

  return data.getPuzzle;
};

export const submitPuzzleTask = async (body) => {
  const { data } = await client.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation completePuzzle($seconds: Int!, $answer: [String!]!, $puzzleId: Int!) {
        completePuzzle(seconds: $seconds, answer: $answer, puzzleId: $puzzleId) {
          points
          success
        }
      }
    `,
    variables: {
      seconds: body.seconds,
      answer: body.answer,
      puzzleId: body.puzzleId,
    },
  });
  return data.completePuzzle;
};

/**
 * 获取组装
 */
export const getBuildupTask = async (id) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getBuildup($id: Int!) {
        getBuildup(id: $id) {
          id
          name
          background
          candidates {
            index
            content
            contentType
            url
          }
          answer
          fakeModelImages
          coordinate {
            x
            y
          }
        }
      }
    `,
    variables: {
      id: +id,
    },
  });
  return data.getBuildup;
};

export const submitBuildupTask = async (body) => {
  const { data } = await client.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation demo($seconds: Int!, $answer: String!, $buildupId: Int!) {
        completeBuildup(seconds: $seconds, answer: $answer, buildupId: $buildupId) {
          success
          points
        }
      }
    `,
    variables: {
      seconds: body.seconds,
      answer: body.answer,
      buildupId: body.buildupId,
    },
  });
  return data.completeBuildup;
};
