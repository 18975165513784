import { client, gql } from './index';
import axios from 'axios';

export const getOSSPolicy = async () => {
  const { data } = await client.query({
    query: gql`
      query OSSPolicy {
        getAliCloudOssUploadPolicy {
          accessid
          host
          expire
          signature
          policy
          dir
          callback
        }
      }
    `,
  });
  return data.getAliCloudOssUploadPolicy;
};

export const uploadToOSS = (host, data) =>
  axios({
    method: 'POST',
    url: host,
    data,
  });
