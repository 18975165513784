import { observable, runInAction } from 'mobx';
import { getMyWechatWorkProfile } from 'api';

/**
 * 用户信息存储的地方
 */
export class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // 个人信息
  @observable
  profile = {
    name: '',
    avatar: '',
    id: '',
    userId: '',
    chanelShopName: '',
  };

  @observable
  userMomentList = [];

  async getMyWechatWorkProfile() {
    const profile = await getMyWechatWorkProfile();
    runInAction(() => {
      this.profile = profile;
    });
  }
}
