import { QUESTION_TYPE } from './consts';

function validate(question) {
  switch (question.type) {
    case QUESTION_TYPE.SINGLE_CHOICE:
      return question.answer.indexOf(question.value) !== -1;
    case QUESTION_TYPE.MULTI_CHOICE:
    case QUESTION_TYPE.FILL_BLANK:
      let res = false;
      if (question.ignoreAnswerOrder) {
        if (question.value.length <= 0 || question.value.length !== question.answer.length) {
          res = false;
        } else {
          res = question.value.every((answer) => question.answer.indexOf(answer) !== -1);
        }
      } else {
        res = question.value.join(';') === question.answer.join(';');
      }
      return res;
    default:
      return false;
  }
}

export default function (questions) {
  const wrongQuestions = [];
  questions.forEach((question, index) => {
    const isValid = validate(question);
    if (!isValid) wrongQuestions.push(index);
  });
  return wrongQuestions;
}
