import { useRef, useEffect } from 'react';
export function useInterval(callback, time) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, time);
    return () => clearInterval(id);
  }, [time]);
}
