import React from 'react';
import { useHistory } from 'react-router-dom';
import { EventCard } from '@comps/EventCard';
import { Avatar } from '@comps/Avatar';

export const getUserCover = (media) => {
  if (!Array.isArray(media) || media.length === 0) {
    return '';
  }
  const { type, url } = media[0];
  if (type === 'IMAGE') {
    return url;
  }
  return url + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast';
};

export const MyCard = ({ avatar, user, year, onShowDetailPage }) => {
  const history = useHistory();
  if (!user) {
    const handleClickUpload = (year) => {
      history.push(`/events/${year}/upload`);
    };

    return (
      <div className="event-list-item event-list-item-right">
        <EventCard type="upload" direction="right" onClick={() => handleClickUpload(year)} />
        <Avatar size="xs" src={avatar} alt="avatar" className="avatar" />
      </div>
    );
  }

  return (
    <div className="event-list-item event-list-item-right">
      <EventCard
        cover={getUserCover(user.media)}
        direction="right"
        type="my"
        summary={user.content}
        onClick={() => {
          onShowDetailPage && onShowDetailPage();
        }}
      />
      <Avatar size="xs" src={avatar} alt="avatar" className="avatar" />
    </div>
  );
};

export const OfficialCard = ({ official, userFinishedStatus }) => {
  const history = useHistory();

  return (
    <div className="event-list-item">
      <Avatar size="xs" src={official.avatar} alt="avatar" className="avatar" />

      <EventCard
        cover={official.cover}
        direction="left"
        title={official.title}
        summary={official.summary}
        sectionTitles={official.sectionTitles}
        onClick={() => {
          history.push({
            pathname: '/events/detail',
            search: `?year=${official.year}`,
          });
        }}
      />
    </div>
  );
};
