import React from 'react';
import './style.scss';

export default function () {
  return (
    <div className="auth-error">
      <p>您没有权限进入</p>
    </div>
  );
}
