/**
 * 足迹 - 消息列表
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Avatar } from '@comps/Avatar';
import { ReactSVG } from 'react-svg';
import IconFavorite from '@images/icon/favorite.svg';
import IconComment from '@images/icon/comment.svg';
import './index.scss';
import { getMyNotifications, updateNotificationLastReadAt, getUserMomentDetail } from 'api/index';
import { getCreateTime } from '@utils/time';
import { getUserCover } from 'views/Main/Events/EventsList/Card';

@inject('rootStore')
@observer
@withRouter
export class MomentNews extends React.Component {
  state = {
    notifications: [],
    latestFetchedItemCreatedAt: '',
  };

  get appStore() {
    return this.props.rootStore.appStore;
  }

  get userStore() {
    return this.props.rootStore.userStore;
  }

  async componentDidMount() {
    await this.userStore.getMyWechatWorkProfile();
    const notifications = await getMyNotifications();
    this.setState({
      notifications,
      latestFetchedItemCreatedAt: notifications[0].createdAt,
    });
    setTimeout(() => {
      updateNotificationLastReadAt(this.state.latestFetchedItemCreatedAt);
    }, 500);
  }

  handleShowDetail = (userId, year) => {
    this.appStore.showDetailAsync(userId, year);
  };

  render() {
    return (
      <div className="moment-news-wrap">
        {this.state.notifications.map((item, index) => {
          const { user, userMoment, content } = item;
          const cover = getUserCover(userMoment.media);
          const newsContent = !!content ? (
            <>
              <ReactSVG className="news-type-icon icon" src={IconComment} wrapper="span" />
              <span className="news-comment-content">{content}</span>
            </>
          ) : (
            <div className="news-type-icon">
              <ReactSVG className="icon" src={IconFavorite} wrapper="span" />
            </div>
          );
          return (
            <div
              className="moment-news-item"
              key={index}
              onClick={() => {
                this.handleShowDetail(userMoment.userId, userMoment.year);
              }}
            >
              <div className="news-item-left">
                <Avatar size="xs" src={user.avatar} />
                <div className="news-content">
                  <div className="user-name">
                    {user.chanelShopName} {user.name}
                  </div>
                  {newsContent}
                  <div className="time">{getCreateTime(item.createdAt)} </div>
                </div>
                {cover && (
                  <div className="news-item-right">
                    <img src={cover} alt="images" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
