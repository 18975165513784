import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { omit } from 'lodash-es';
import c from 'classnames';
import './index.scss';

export class Button extends Component {
  static defaultProps = {
    /**
     * 按钮颜色
     */
    reverse: false,
    /**
     * normal 为 38 行高，small 为 32（暂定就这两种）
     */
    size: 'normal',
    blocked: false,
    disabled: false,
    /**
     * 当 Button 有 to 属性的时候，行为同 Link，参数也同 Link，直接透传
     */
    to: '',
  };

  state = {
    showLoading: false,
  };

  get className() {
    const { className, disabled, reverse, size, blocked } = this.props;
    return c('comp-button', className, {
      [`comp-button-size-${size}`]: size,
      'comp-button-reverse': reverse,
      'comp-button-disabled': disabled,
      'comp-button-blocked': blocked,
    });
  }
  get omitProps() {
    return omit(this.props, ['reverse', 'size', 'blocked', 'disabled']);
  }

  renderContent() {
    const { children } = this.props;
    return children ? <span className="comp-button-content">{children}</span> : null;
  }

  render() {
    const { to } = this.props;
    const content = this.renderContent();
    return !!to ? (
      <Link className={this.className} {...this.omitProps}>
        {content}
      </Link>
    ) : (
      <button disabled={this.props.disabled} className={this.className} onClick={this.props.onClick} {...this.omitProps}>
        {content}
      </button>
    );
  }
}

export * from './FixedButtonGroup';
