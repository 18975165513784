import dayjs from 'dayjs';
import { ActionSheet } from 'antd-mobile';
import { cropId, CDN_HOST } from '../api';
import { getWechatSign } from '../api/auth';
import { getOSSPolicy, uploadToOSS } from '../api/oss';
import { isAndroid } from './index';

export default async function (accepts = ['image', 'image-camera', 'video', 'video-camera']) {
  const buttons = [
    { label: '从相册上传照片', key: 'image' },
    { label: '拍照上传', key: 'image-camera' },
    { label: '录制视频', key: 'video' },
    { label: '从相册上传视频', key: 'video-camera' },
  ].filter((item) => accepts.indexOf(item.key) !== -1);
  let files = [];
  if (buttons.length === 1) {
    files = await chooseImage(buttons[0].key);
  } else {
    buttons.push({ label: '取消', key: 'cancel' });
    files = await new Promise((resolve) => {
      ActionSheet.showActionSheetWithOptions(
        {
          cancelButtonIndex: buttons.length - 1,
          maskClosable: true,
          options: buttons.map(({ label }) => label),
        },
        (index) => {
          const button = buttons[index];
          resolve(chooseImage(button.key));
        }
      );
    });
  }
  const OSSData = await getOSSPolicy();
  if (!Array.isArray(files)) {
    files = [files];
  }
  const res = await Promise.all(files.map((file) => uploadFile({ OSSData, ...file })));
  return res;
}

async function uploadFile({ OSSData, file, type }) {
  const form = new FormData();
  const obj = {
    key: OSSData.dir + file.name.replace(/\s*/g, ''),
    OSSAccessKeyId: OSSData.accessid,
    policy: OSSData.policy,
    Signature: OSSData.signature,
  };
  for (let key in obj) {
    form.append(key, obj[key]);
  }
  form.append('file', file);
  await uploadToOSS(OSSData.host, form);
  return {
    url: CDN_HOST + '/' + obj.key,
    type,
  };
}

export const initWxConfig = async () => {
  const sign = await getWechatSign();
  window.wx.config({
    ...sign,
    timestamp: dayjs(sign.timestamp).valueOf(),
    appId: cropId, // 必填，公众号的唯一标识
    jsApiList: ['chooseImage', 'getLocalImgData', 'hideOptionMenu'], // 必填，需要使用的JS接口列表
  });
};

function chooseImage(type) {
  const el = document.createElement('input');
  el.type = 'file';
  el.hidden = true;
  return new Promise((resolve, reject) => {
    switch (type) {
      case 'image':
        if (isAndroid()) {
          el.accept = 'image/*';
          el.multiple = true;
          el.addEventListener('change', function () {
            let files = Array.from(this.files);
            if (!/image/i.test(this.files[0].type)) {
              reject(new Error('只能选择图片'));
              return;
            }
            if (files.length > 9) files = files.slice(0, 9);
            resolve(files.map((file) => ({ file, type })));
          });
          document.body.appendChild(el);
          el.click();
        } else {
          window.wx.ready(function () {
            window.wx.chooseImage({
              count: 9, // 默认9
              sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
              sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
              success: async function (res) {
                const localIds = res.localIds;
                const files = await localIds2Files(localIds);
                resolve(files);
              },
            });
          });
        }
        break;
      case 'image-camera':
        if (isAndroid()) {
          el.accept = 'image/*';
          el.setAttribute('capture', 'camera');
          el.addEventListener('change', function () {
            resolve({ file: this.files[0], type });
          });
          document.body.appendChild(el);
          el.click();
        } else {
          window.wx.ready(function () {
            window.wx.chooseImage({
              count: 1, // 默认9
              sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
              sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
              success: function (res) {
                const localId = res.localIds[0];
                window.wx.getLocalImgData({
                  localId,
                  success(res) {
                    resolve({ type: 'image', file: dataURLtoFile(res.localData, `${Date.now()}.png`) });
                  },
                });
              },
            });
          });
        }
        break;
      case 'video':
        type = 'video';
        el.accept = 'video/*';
        el.setAttribute('capture', 'environment');
        el.addEventListener('change', function () {
          resolve({ file: this.files[0], type });
        });
        document.body.appendChild(el);
        el.click();
        break;
      case 'video-camera':
        type = 'video';
        el.accept =
          'video/x-matroska, video/mp4, video/x-msvideo, application/x-shockwave-flash, video/x-ms-wmv, application/vnd.rn-realmedia-vbr, video/quicktime, video/mpeg';
        el.addEventListener('change', function () {
          resolve({ file: this.files[0], type });
        });
        document.body.appendChild(el);
        el.click();
      default:
      // TODO
    }
  });
}

function localIds2Files(localIds) {
  return Promise.all(localIds.map(localId2File));
}

function localId2File(localId) {
  return new Promise((resolve) => {
    window.wx.getLocalImgData({
      localId,
      success(res) {
        const file = dataURLtoFile(res.localData, `${Date.now()}.png`);
        resolve({ type: 'image', file });
      },
    });
  });
}

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
