/**
 * 对话卡片
 */
import React from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from '../Button';

import IconUpload from '../../images/icon/upload.svg';
import c from 'classnames';
import './index.scss';

function EventCardWrap(props) {
  const { direction, type } = props;
  const cls = c(
    'event-card',
    {
      [`event-card-direction-${direction}`]: direction,
      [`event-card-type-${type}`]: type,
    },
    props.className
  );
  return (
    <div className={cls} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

function UploadEventCard(props) {
  return (
    <EventCardWrap className={props.className} onClick={props.onClick}>
      <div className="event-card-upload-inner">
        <ReactSVG src={IconUpload} />
        <div className="event-card-upload-title">上传你的故事</div>
      </div>
    </EventCardWrap>
  );
}

const CardDescription = ({ sectionTitles }) => {
  if (!Array.isArray(sectionTitles) || sectionTitles.length === 0) {
    return null;
  }
  return (
    <div className="event-card-description">
      {sectionTitles.map((title, index) => {
        return (
          <p key={index} className="event-card-description-item">
            {title}
          </p>
        );
      })}
    </div>
  );
};

export const EventCard = function EventCard(props) {
  const { type } = props;

  if (type === 'upload') {
    return <UploadEventCard className="event-card-type-upload" direction="right" {...props} />;
  }

  const handleContentClick = () => {
    if (props.onContentClick) {
      props.onContentClick();
    }
  };

  return (
    <EventCardWrap {...props}>
      <div onClick={handleContentClick}>
        {props.cover && (
          <div className="event-card-cover">
            <img src={props.cover} alt="title" />
          </div>
        )}
        <div className="event-card-content">
          <h2 className="event-card-content-title">{props.title}</h2>
          <p className="event-card-content-summary">{props.summary}</p>
          <CardDescription sectionTitles={props.sectionTitles} />
        </div>
      </div>

      {type === 'official' && (
        <div className="event-card-footer">
          <Button size="small">点击查看全文及挑战</Button>
        </div>
      )}
    </EventCardWrap>
  );
};

EventCard.defaultProps = {
  direction: 'left',
  title: '',
  cover: '',
  summary: '',
  sectionTitles: [],
  /**
   * type: 'upload' | 'official' | 'user' | 'preview'
   * preview: 我的年鉴里使用
   */
  type: 'official',
};
