import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useMount, useMap } from 'react-use';
import URLSearchParams from '@ungap/url-search-params';
import { getTask, submitTask } from '../../api';
import Swiper from '../../components/Swiper';
import QuestionBuilder from './components/QuestionBuilder';
import './style.scss';
import { QUESTION_TYPE } from './consts';
import { Button } from '../../components/Button';
import MessageBox from '../../components/MessageBox';
import validator from './validator';
import cloneDeep from 'lodash-es/cloneDeep';
import isArray from 'lodash-es/isArray';

let startAt = 0;
export default function Question(props) {
  const { history, location } = props;
  const query = new URLSearchParams(location.search);
  const isReadOnly = query.get('status') === 'finished';
  const { id } = useParams();
  const [task, setTask] = useState({});
  const [questions, setQuestions] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [messageBoxConfig, { setAll }] = useMap({
    visible: false,
    content: '提交成功',
    showClose: true,
    onClose: () => {
      setAll({
        ...messageBoxConfig,
        visible: false,
      });
      history.replace('/events/detail');
    },
  });
  const msgBoxConfigRef = useRef();
  msgBoxConfigRef.current = messageBoxConfig;
  useMount(() => {
    if (!isReadOnly) {
      msgBoxConfigRef.current = {
        visible: true,
        content: (
          <span>
            请尽快完成挑战，
            <br />
            用时越少 得分越高
          </span>
        ),
        onClose() {
          startAt = Date.now();
          msgBoxConfigRef.current = {
            ...msgBoxConfigRef.current,
            visible: false,
          };
          setAll(msgBoxConfigRef.current);
        },
      };
      setAll(msgBoxConfigRef.current);
    }
  });

  const handleValueChange = (question, val) => {
    setQuestions(
      questions.map((q) => ({
        ...q,
        value: question.id === q.id ? val : q.value,
      }))
    );
  };

  const initQuestionValue = (question) => {
    switch (question.type) {
      case QUESTION_TYPE.SINGLE_CHOICE:
        if (isReadOnly) return question.answer[0];
        return null;
      case QUESTION_TYPE.MULTI_CHOICE:
        if (isReadOnly) return question.answer;
        return [];
      case QUESTION_TYPE.FILL_BLANK:
        if (isReadOnly) return question.answer;
        return [];
      default:
      // TODO
    }
  };

  const handleSubmit = async () => {
    const wrongs = validator(questions);
    if (wrongs.length > 0) {
      const q = cloneDeep(questions);
      setQuestions(q.map((item, index) => (wrongs.indexOf(index) !== -1 ? { ...item, invalid: true } : { ...item, invalid: false })));
      msgBoxConfigRef.current = {
        visible: true,
        content: '作答有误，请更正',
        onClose() {
          setAll({
            ...msgBoxConfigRef.current,
            visible: false,
          });
        },
      };
      setAll(msgBoxConfigRef.current);
    } else {
      try {
        const { points } = await submitTask({
          answer: questions.map((question) => ({
            questionId: question.id,
            answer: isArray(question.value) ? question.value : [question.value],
          })),
          seconds: parseInt((Date.now() - startAt) / 1000),
          quizId: Number(id),
        });
        msgBoxConfigRef.current = {
          visible: true,
          content: (
            <span>
              恭喜完成挑战
              <br />
              本轮得分 {points} 分
            </span>
          ),
          showClose: true,
          onClose: () => {
            msgBoxConfigRef.current = {
              ...msgBoxConfigRef.current,
              visible: false,
            };
            setAll(msgBoxConfigRef.current);
            history.replace(`/events/detail?year=${query.get('year')}`);
          },
        };
        setAll(msgBoxConfigRef.current);
      } catch (e) {
        msgBoxConfigRef.current = {
          content: '抱歉，服务器开小差了',
          showClose: true,
          visible: true,
          onClose() {
            msgBoxConfigRef.current = {
              ...msgBoxConfigRef.current,
              visible: false,
            };
            setAll(msgBoxConfigRef.current);
          },
        };
        setAll(msgBoxConfigRef.current);
      }
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  useMount(async () => {
    const t = await getTask(Number(id));
    setTask(t);
    setQuestions(
      t.questions.map((q) => ({
        ...q,
        value: q.value || initQuestionValue(q),
        answer: q.answer,
        ignoreAnswerOrder: q.ignoreAnswerOrder,
      }))
    );
    setIsReady(true);
  });
  return (
    <div className="question">
      <div className="question-content">
        {isReady && (
          <Swiper options={{ pagination: { el: '.swiper-pagination' } }}>
            {task.description.map((banner, index) => {
              if (banner.type === 'IMAGE') return <img className="question-banner__img" src={banner.url} alt={`banner-${index}`} key={`banner-${index}`} />;
              return <video poster={banner.videoSnapshot} className="question-banner__img" controls src={banner.url} key={`banner-${index}`} />;
            })}
          </Swiper>
        )}
        {isReady &&
          questions.map((question, index) => (
            <QuestionBuilder isReadOnly={isReadOnly} key={index} index={index} question={question} onChange={(val) => handleValueChange(question, val)} />
          ))}
      </div>
      <div className="question-footer">
        <Button onClick={isReadOnly ? handleBack : handleSubmit}>{isReadOnly ? '返回' : '提交'}</Button>
      </div>
      <MessageBox {...messageBoxConfig} />
    </div>
  );
}
