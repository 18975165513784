/**
 * @todo 替换成真实 icon
 */
import React from 'react';
import { ReactSVG } from 'react-svg';
import IconChat from '../../images/icon/chat.svg';
import IconChatActive from '../../images/icon/chat-active.svg';

import IconMoment from '../../images/icon/moment.svg';
import IconMomentActive from '../../images/icon/moment-active.svg';

import IconRank from '../../images/icon/rank.svg';
import IconRankActive from '../../images/icon/rank-active.svg';

import IconMine from '../../images/icon/mine.svg';
import IconMineActive from '../../images/icon/mine-active.svg';

/**
 * 对话
 */
export function EventsIcon() {
  return <ReactSVG src={IconChat} />;
}
export function EventsSelectedIcon() {
  return <ReactSVG src={IconChatActive} />;
}

/**
 * 足迹
 */
export function MomentIcon() {
  return <ReactSVG src={IconMoment} />;
}
export function MomentSelectedIcon() {
  return <ReactSVG src={IconMomentActive} />;
}

/**
 * 排行
 */
export function RankIcon() {
  return <ReactSVG src={IconRank} />;
}
export function RankSelectedIcon() {
  return <ReactSVG src={IconRankActive} />;
}

/**
 * 我的
 */
export function UserIcon() {
  return <ReactSVG src={IconMine} />;
}
export function UserSelectedIcon() {
  return <ReactSVG src={IconMineActive} />;
}
