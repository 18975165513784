import React from 'react';
import { RankItem } from '../RankItem';
import { getPointRank } from 'api/rank';
import SwiperRuler from '../../../../components/SwipeRuler';
import { CurUserRankItem } from '../CurUserRankItem';
import { getMomentYearList } from 'api/index';
import { debounce } from 'lodash-es';

export class BeforeRankList extends React.Component {
  state = {
    rankList: [],
    curUserInfo: {},
    years: [],
    curPageNum: 0,
    curYear: '2000',
  };

  scroller = null;

  onScroll = () => {
    const diff = this.scroller.scrollHeight - this.scroller.scrollTop - this.scroller.clientHeight;
    if (diff <= 50) {
      const { curPageNum, curYear, rankList } = this.state;
      const pageSize = 20;
      if (curPageNum + 1 * pageSize > rankList.length) {
        return;
      }
      this.setState({ curPageNum: curPageNum + 1 });
      this.fetchRankList(curYear, curPageNum + 1);
    }
  };

  async componentDidMount() {
    this.initScroller();
    const yearList = await getMomentYearList();
    this.setState({
      years: yearList.map((year) => ({ label: String(year), value: year })),
      curYear: yearList[0],
    });
    this.fetchRankList(yearList[0], 0);
  }

  initScroller = () => {
    const scroller = document.querySelector('.tab-bar-content');
    this.scroller = scroller;
    this.scroller.addEventListener('scroll', debounce(this.onScroll.bind(this), 200), false);
  };

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.onScroll, false);
  }

  fetchRankList = async (year, pageNum) => {
    const { rankList, curUserInfo } = await getPointRank(year, pageNum);
    const sortRankList = rankList.sort((a, b) => a.position - b.position);
    const contactList = this.state.rankList.concat(sortRankList);
    this.setState({
      rankList: contactList,
    });
    if (!!curUserInfo) {
      this.setState({
        curUserInfo,
      });
    }
  };

  handleYearChange = (year) => {
    const curYear = String(year.value);
    this.setState({
      curYear,
      rankList: [],
    });
    this.fetchRankList(curYear);
  };

  render() {
    const { rankList, curUserInfo } = this.state;

    const { position, points, user } = curUserInfo;
    if (!position) {
      return null;
    }
    return (
      <div className="rank-list before-rank-list">
        <div className="rank-list-fixed-wrapper">
          <SwiperRuler items={this.state.years} onChange={this.handleYearChange} />
          {/* 我的排名 */}
          <CurUserRankItem position={position} points={points} user={user} />
        </div>

        <div className="rank-list-content">
          {rankList.map((item, index) => {
            return <RankItem key={index} userInfo={item.user} position={item.position} points={item.points} />;
          })}
        </div>
      </div>
    );
  }
}
