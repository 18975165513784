import React from 'react';
import { Avatar } from '@comps/Avatar/index';
import { getRankTitle } from './img/getRankTitle';

export const CurUserRankItem = ({ user, position, points }) => {
  return (
    <div className="rank-item rank-item-mine">
      <div className="rank-icon">
        <span className="my-rank">{getRankTitle(position)}</span>
        <div className="helper-text">我的排名</div>
      </div>
      <div className="rank-item-content">
        <div className="rank-item-user-info">
          <Avatar size="lg" src={user.avatar} />
          <div className="rank-item-profile">
            <div className="rank-item-user-name">{user.name || ''}</div>
            <div className="rank-item-shop-name">{user.chanelShopName || ''}</div>
          </div>
        </div>
        <div className="rank-item-score">{points}分</div>
      </div>
    </div>
  );
};
