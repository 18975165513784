import React from 'react';
import './style.scss';

export default class Description extends React.Component {
  render() {
    return (
      <div className="description">
        <img src="https://coco-cdn.chanel-digital.com/j12/uploads/828a5335-a32a-48ee-a3d2-c02b354e154f/des_01.jpg" alt="description-1" />
        <img src="https://coco-cdn.chanel-digital.com/j12/uploads/828a5335-a32a-48ee-a3d2-c02b354e154f/des_02.jpg" alt="description-1" />
        <img src="https://coco-cdn.chanel-digital.com/j12/uploads/828a5335-a32a-48ee-a3d2-c02b354e154f/des_03.jpg" alt="description-1" />
        <img src="https://coco-cdn.chanel-digital.com/j12/uploads/828a5335-a32a-48ee-a3d2-c02b354e154f/des_04.jpg" alt="description-1" />
      </div>
    );
  }
}
