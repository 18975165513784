import { configure } from 'mobx';
import { AppStore } from './appStore';
import { UserStore } from './userStore';
// 使用严格模式
configure({
  enforceActions: 'always',
});

class RootStore {
  constructor() {
    this.appStore = new AppStore(this);
    this.userStore = new UserStore(this);
  }
}

export default new RootStore();
