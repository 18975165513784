import { client, gql } from './index';

/**
 * 获取排行
 */
export const getPointRank = (year, pageNum = 0) => {
  const pageSize = 20;
  let str = `
  getPointRank(year: $year, pagination: {take: ${pageSize}, skip: ${pageSize * pageNum}}) {
    position
    points
    user {
      userId
      name
      avatar
      position
      chanelShopName
      alias
      chanelWDID
    }
  }`;
  if (pageNum === 0) {
    str += `getUserRankPosition(year: $year) {
      user {
        userId
        name
        avatar
        position
        chanelShopName
        alias
        chanelWDID
      }
      points
      position
    }`;
  }
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query demo($year: String!) {
          ${str}
        }
      `,
      variables: {
        year: String(year),
      },
    })
    .then(({ data }) => {
      return {
        rankList: data.getPointRank,
        curUserInfo: data.getUserRankPosition,
      };
    });
};

export const getKeyWordPointRank = (keyword, pageNum = 0) => {
  const pageSize = 50;
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query demo($keyword: String!) {
          getPointRank(keyword: $keyword, pagination: {take: ${pageSize}, skip: ${pageSize * pageNum}}) {
            position
            points
            user {
              userId
              name
              avatar
              position
              chanelShopName
              alias
              chanelWDID
            }
          }
        }
      `,
      variables: {
        keyword,
      },
    })
    .then(({ data }) => data.getPointRank);
};

/**
 * 获取排行榜总数
 */
export const getPointRankItemCount = (year) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query($year: String!) {
          query
          demo {
            getPointRankItemCount(year: $year) {
              count
            }
          }
        }
      `,
      variables: {
        year: year,
      },
    })
    .then(({ data }) => data.getPointRank);
};
