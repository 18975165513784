import { client, gql } from './index';

/**
 * 获取新消息数量
 */
export const getNewNotificationCount = () => {
  return client
    .mutate({
      mutation: gql`
        query demo {
          getNewNotificationCount {
            count
          }
        }
      `,
    })
    .then(({ data }) => data.getNewNotificationCount.count);
};

/**
 * 获取官方足迹，对话列表使用
 */
export const getMomentYearList = () => {
  return client
    .mutate({
      mutation: gql`
        query demo {
          getEnabledOfficialMoments {
            year
          }
        }
      `,
    })
    .then(({ data }) => {
      const enabledOfficialMoments = data.getEnabledOfficialMoments;
      return enabledOfficialMoments.map((item) => {
        return +item.year;
      });
    });
};

/**
 * 根据年限获取官方和用户的足迹列表
 * @param {string} year
 */
export const getMoments = ({ year, lastItemId, pageSize = 20, orderBy }) => {
  let str = '';
  const isFirstFetch = !lastItemId;
  if (isFirstFetch) {
    str += `
    getOfficialMoment(year: $year) {
      id
      year
      content
      cover
      summary
      media {
        type
        url
        thumbnail
        videoSnapshot
        videoSnapshotThumbnail
      }
      avatar
      createdAt
    }`;
  }
  const pagination = isFirstFetch
    ? `{take: ${pageSize}, orderBy: "${orderBy}", orderByMode: "DESC"}`
    : `{take: ${pageSize}, lastItemId: ${lastItemId}, orderBy: "${orderBy}", orderByMode: "DESC"}`;

  str += `
  userMoments: getUserMoments(year: $year, pagination: ${pagination}) {
    id
    year
    userId
    content
    createdAt
    media {
      url
      type
    }
    user {
      id
      name
      chanelShopName
      avatar
    }
    comments(pagination: {}) {
      content
      user {
        userId
        name
        chanelShopName
      }
    }
    likes(pagination: {}) {
      user {
        userId
        name
        chanelShopName
      }
    }
  }`;
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query demo($year: String!) {
         ${str}
        }
      `,
      variables: {
        year: String(year),
      },
    })
    .then(({ data }) => {
      return {
        officialMoment: data.getOfficialMoment || null,
        userMoments: data.userMoments,
      };
    });
};

/**
 * 获取给官方点赞的用户列表
 */
export const getOfficialMomentCommentAndLikes = (id) => {
  return client
    .mutate({
      mutation: gql`
        query demo($id: Int!) {
          getOfficialMomentLikes(officialMomentId: $id) {
            user {
              id
              userId
              name
              chanelShopName
            }
          }
          getOfficialMomentComments(officialMomentId: $id, pagination: {}) {
            content
            user {
              userId
              chanelShopName
              name
            }
            refUser {
              userId
              chanelShopName
              name
            }
          }
        }
      `,
      variables: {
        id: id,
      },
    })
    .then(({ data }) => {
      return {
        commentList: data.getOfficialMomentComments,
        likeList: data.getOfficialMomentLikes,
      };
    });
};

/**
 * 官方点赞与取消点赞
 */
export const mutationLikeOfficialMoment = (id, isLike) => {
  let query = '';
  if (isLike) {
    query = `
    mutation demo($id: Int!) {
      likeOfficialMoment(officialMomentId: $id) {
        success
      }
    }
    `;
  } else {
    query = `
    mutation demo($id: Int!) {
      unlikeOfficialMoment(officialMomentId: $id) {
        success
      }
    }
    `;
  }
  return client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        id,
      },
    })
    .then(({ data }) => {
      return isLike ? data.likeOfficialMoment.success : data.unlikeOfficialMoment.success;
    });
};

/**
 * 评论官方足迹
 */
export const mutationCommentOfficialMoment = (officialMomentId, content, refUserId) => {
  return client
    .mutate({
      mutation: gql`
        mutation demo($officialMomentId: Int!, $content: String!, $refUserId: Int!) {
          commentOfficialMoment(officialMomentId: $officialMomentId, content: $content, refUserId: $refUserId) {
            success
          }
        }
      `,
      variables: {
        officialMomentId,
        content,
        refUserId,
      },
    })
    .then(({ data }) => {
      return data.commentOfficialMoment.success;
    });
};

/**
 * 评论用户足迹
 */
export const mutationCommentUserMoment = (userMomentId, content, refUserId) => {
  return client
    .mutate({
      mutation: gql`
        mutation demo($userMomentId: Int!, $content: String!, $refUserId: Int!) {
          commentUserMoment(userMomentId: $userMomentId, content: $content, refUserId: $refUserId) {
            success
          }
        }
      `,
      variables: {
        userMomentId,
        content,
        refUserId,
      },
    })
    .then(({ data }) => {
      return data.commentUserMoment.success;
    });
};

/**
 * 用户点赞与取消点赞
 */
export const mutationLikeUserMoment = (id, isLike) => {
  let query = '';
  if (isLike) {
    query = `
    mutation demo($id: Int!) {
      likeUserMoment(userMomentId: $id) {
        success
      }
    }
    `;
  } else {
    query = `
    mutation demo($id: Int!) {
      unlikeUserMoment(userMomentId: $id) {
        success
      }
    }
    `;
  }
  return client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        id,
      },
    })
    .then(({ data }) => {
      return isLike ? data.likeUserMoment.success : data.unlikeUserMoment.success;
    });
};

export const getUserMomentDetail = (userId, year) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query demo($year: String!, $userId: Int!) {
          userMoments: getUserMoments(year: $year, userId: $userId, pagination: { take: 22, orderBy: "year", orderByMode: "DESC" }) {
            id
            year
            userId
            content
            createdAt
            media {
              url
              type
            }
            user {
              id
              name
              chanelShopName
              avatar
            }
            comments(pagination: {}) {
              content
              user {
                userId
                name
                chanelShopName
              }
            }
            likes(pagination: {}) {
              user {
                userId
                name
                chanelShopName
              }
            }
          }
        }
      `,
      variables: {
        year: String(year),
        userId: +userId,
      },
    })
    .then(({ data }) => {
      return data.userMoments;
    });
};

/**
 * 用户删除自己的足迹
 */
export const removeUserMoment = (userMomentId) => {
  return client
    .mutate({
      mutation: gql`
        mutation demo($userMomentId: Float!) {
          removeUseMoment(userMomentId: $userMomentId) {
            success
          }
        }
      `,
      variables: {
        userMomentId: Number(userMomentId),
      },
    })
    .then(({ data }) => {
      return data.removeUseMoment.success;
    });
};

/**
 * 获取消息列表
 */
export const getMyNotifications = () => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query demo {
          getMyNotifications(pagination: {}) {
            ... on UserCommentUserMoment {
              id
              content
              createdAt
              user {
                avatar
                name
                chanelShopName
              }
              userMoment {
                id
                userId
                year
                content
                media {
                  type
                  url
                }
              }
            }
            ... on UserLikeUserMoment {
              id
              createdAt
              user {
                avatar
                name
                chanelShopName
              }
              userMoment {
                id
                userId
                year
                content
                media {
                  type
                  url
                }
              }
            }
          }
        }
      `,
    })
    .then(({ data }) => {
      return data.getMyNotifications;
    });
};

export const updateNotificationLastReadAt = (latestFetchedItemCreatedAt) => {
  return client
    .mutate({
      mutation: gql`
        mutation demo($latestFetchedItemCreatedAt: DateTime!) {
          updateNotificationLastReadAt(latestFetchedItemCreatedAt: $latestFetchedItemCreatedAt) {
            success
          }
        }
      `,
      variables: {
        latestFetchedItemCreatedAt: latestFetchedItemCreatedAt,
      },
    })
    .then(({ data }) => {
      return data.updateNotificationLastReadAt.success;
    });
};
