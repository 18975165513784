/**
 * 阅读 - 详情
 */
import React from 'react';
import c from 'classnames';
import URLSearchParams from '@ungap/url-search-params';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FixedButtonGroup, Button } from '@comps/Button';
import { getOfficialMoment, getFinishedStatus } from 'api/event';

import { ReactSVG } from 'react-svg';
import IconReadTime from '../../../../images/icon/read-time.svg';
import IconReadJ12 from '../../../../images/icon/read-J12.svg';
import IconReadWorld from '../../../../images/icon/read-world.svg';
import IconReadChina from '../../../../images/icon/read-china.svg';

import './index.scss';
import { isNull } from 'lodash-es';

@withRouter
@inject('rootStore')
@observer
export class EventsDetail extends React.Component {
  state = {
    year: '',
    descMap: {
      world: [],
      china: [],
      j12: [],
    },
    puzzleId: '',
    quizId: '',
    showDetailIcon: false,
    isQuizFinished: null,
    isGameFinished: null,
  };
  get appStore() {
    return this.props.rootStore.appStore;
  }

  async componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const year = query.get('year');
    this.setState({ year });
    const momentList = await getOfficialMoment(year);
    const { puzzleId, quizId, buildupId } = momentList;

    const { finishedQuiz, finishedBuildup, finishedPuzzle } = await getFinishedStatus({ puzzleId, quizId, buildupId });
    const descMap = { world: [], china: [], j12: [] };
    momentList.descriptions.forEach((item) => {
      descMap[item.section.toLowerCase()].push(item);
    });
    this.setState({
      descMap,
      isGameFinished: !!finishedBuildup || !!finishedPuzzle,
      isQuizFinished: !!finishedQuiz,
      puzzleId: puzzleId,
      quizId: quizId,
      buildupId: buildupId,
    });
  }
  handleTriggerDetailIcon = () => {
    this.setState({
      showDetailIcon: !this.state.showDetailIcon,
    });
  };
  handleGotoGame = () => {
    const { history } = this.props;
    const { puzzleId, buildupId, isGameFinished } = this.state;
    const getPathname = () => {
      let urlStr = '';
      if (puzzleId) {
        urlStr = `/puzzle/${puzzleId}`;
      }
      if (buildupId) {
        urlStr = `/buildup/${buildupId}`;
      }
      if (isGameFinished) {
        urlStr = '/buildup-answer' + urlStr;
      }
      return urlStr;
    };

    history.push({
      pathname: getPathname(),
      search: `?year=${this.state.year}${isGameFinished ? '&status=finished' : ''}`,
    });
  };
  handleGotoQuiz = () => {
    const { history } = this.props;
    const { quizId, isQuizFinished } = this.state;
    history.push({
      pathname: `/questions/${quizId}`,
      search: `?year=${this.state.year}${isQuizFinished ? '&status=finished' : ''}`,
    });
  };

  getStatusText = (status) => {
    if (isNull(status)) {
      return '(准备中)';
    } else if (status) {
      return '(已完成)';
    }
    return '';
  };
  render() {
    const { descMap, isGameFinished, isQuizFinished, showDetailIcon } = this.state;
    const getIconCls = (hash) => {
      const isMatchHash = window.location.hash.includes(hash);
      return c('detail-icon', {
        'detail-icon-active': isMatchHash,
        'icon-world': hash === 'world',
        'icon-china': hash === 'china',
        'icon-j12': hash === 'j12',
      });
    };
    return (
      <div className="event-detail">
        <div
          className={c('quick-menu', {
            'detail-icon-hidden': !showDetailIcon,
          })}
        >
          <ReactSVG className="icon-time" src={IconReadTime} onClick={this.handleTriggerDetailIcon} />
          <div className="detail-icon-groups">
            <a className={getIconCls('world')} href="#detail-world">
              <ReactSVG src={IconReadWorld} />
            </a>
            <a className={getIconCls('china')} href="#detail-china">
              <ReactSVG src={IconReadChina} />
            </a>
            <a className={getIconCls('j12')} href="#detail-j12">
              <ReactSVG src={IconReadJ12} />
            </a>
          </div>
        </div>
        <div className="detail-content">
          {Object.keys(descMap).map((key, index) => {
            const content = descMap[key];
            return (
              <div className="detail-item" id={`detail-${key}`} key={index}>
                {content.map((item) => {
                  if (item.type !== 'VIDEO') {
                    return <img className="detail-img" src={item.url} alt={item.title} />;
                  }
                  return (
                    <video
                      className="detail-video"
                      playsInline
                      webkit-plays-inline="true"
                      preload="true"
                      x5-video-player-type="h5"
                      x5-video-player-fullscreen="false"
                      x5-playsinline="true"
                      controls
                      src={item.url}
                      poster={`${item.url}?x-oss-process=video/snapshot,t_0,f_jpg`}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <FixedButtonGroup>
          <Button onClick={this.handleGotoQuiz} disabled={isNull(isQuizFinished)}>
            问答挑战
            <span className="finished-status">{this.getStatusText(isQuizFinished)}</span>
          </Button>

          <Button onClick={this.handleGotoGame} disabled={isNull(isGameFinished)}>
            游戏挑战
            <span className="finished-status">{this.getStatusText(isGameFinished)}</span>
          </Button>
        </FixedButtonGroup>
      </div>
    );
  }
}
