import React from 'react';

import Rank1 from './rank-1.png';
import Rank2 from './rank-2.png';
import Rank3 from './rank-3.png';
export const getRankTitle = (num) => {
  if (num === 1) {
    return <img src={Rank1} className="" alt="1" />;
  } else if (num === 2) {
    return <img src={Rank2} alt="2" />;
  } else if (num === 3) {
    return <img src={Rank3} alt="3" />;
  }
  return num;
};
