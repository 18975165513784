/**
 * 提示弹窗
 */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

export default function MessageBox({ visible, content, showClose = true, closeOnClickShadow = false, onClose, buttonText, onClickButton }) {
  const [showShadow, setShowShadow] = useState(false);
  const handleClose = (e) => {
    e && e.preventDefault();
    onClose();
  };
  const handleClickShadow = (e) => {
    if (e.target.className === 'messagebox' && closeOnClickShadow) {
      handleClose();
    }
  };
  const handleExited = () => {
    setShowShadow(false);
  };
  useEffect(() => {
    if (visible) setShowShadow(visible);
  }, [visible]);
  return (
    <div className={classnames('messagebox', { hidden: !showShadow })} onClick={handleClickShadow}>
      <CSSTransition in={visible} timeout={500} classNames="fade" onExited={handleExited}>
        <div className="messagebox-container">
          <div className="messagebox-container__content">{content}</div>
          {buttonText && (
            <button className="messagebox-container__button" onClick={onClickButton}>
              {buttonText}
            </button>
          )}
        </div>
      </CSSTransition>
      {showClose && (
        <div className="messagebox-footer">
          <a className="messagebox-footer__close iconfont iconclose" href="#" onClick={handleClose} />
        </div>
      )}
    </div>
  );
}
