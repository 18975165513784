/**
 * 足迹（朋友全列表）
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Timeline from '@comps/Timeline';
import c from 'classnames';
import { getMoments, getMomentYearList } from 'api/index';
import { Tips } from './Tips';
import { OfficialMoment } from './OfficialMomentItem';
import { UserMoment } from './UserMomentItem';
import { runInAction } from 'mobx';
import { debounce } from 'lodash-es';
import { Icon } from 'antd-mobile';
import { Search } from './Search';
import './index.scss';

@withRouter
@inject('rootStore')
@observer
export class MomentList extends React.Component {
  state = {
    orderBy: 'id', // id || likesCount || commentsCount
    timeList: [],
    officialMoment: {},
    userMoments: [],
    isReady: false,
    lastItemId: 0,
    curYear: '2000',
    maxPageNum: false, // 已经最大分页了，可以不需要滚动
  };

  textareaRef = null;

  get appStore() {
    return this.props.rootStore.appStore;
  }
  get userStore() {
    return this.props.rootStore.userStore;
  }
  get timeList() {
    const { timeList } = this.state;
    const ellipsisArr = new Array(21 - timeList.length).fill({
      ellipsis: true,
    });
    return timeList
      .map((year, index) => {
        return { label: year, value: year };
      })
      .concat(ellipsisArr)
      .reverse();
  }

  scroller = null;

  onScroll = () => {
    const diff = this.scroller.scrollHeight - this.scroller.scrollTop - this.scroller.clientHeight;
    if (diff <= 50) {
      const { lastItemId, maxPageNum } = this.state;
      if (maxPageNum) {
        return;
      }

      const fetchMomentsDebounced = debounce(this.fetchMoments, 2);

      fetchMomentsDebounced(lastItemId);
    }
  };

  async componentDidMount() {
    this.initScroller();
    await this.initTimelineList();
  }

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.onScroll, false);
    runInAction(() => {
      this.appStore.userMoments = [];
    });
  }

  initScroller() {
    const scroller = document.querySelector('.tab-bar-content');
    this.scroller = scroller;
    this.scroller.addEventListener('scroll', debounce(this.onScroll.bind(this), 200), false);
  }

  initTimelineList = async () => {
    const timeList = await getMomentYearList();
    this.setState({
      timeList,
      curYear: timeList[timeList.length - 1],
      isReady: true,
    });
    this.handleActiveYearChange({
      value: timeList[timeList.length - 1],
    });
  };

  /**
   * refresh 用于控制是拼接还是刷新足迹，在下拉滚动的时候为拼接，在 tab 切换的时候为重置
   */
  fetchMoments = async (lastItemId, refresh = false) => {
    const pageSize = 20;
    const { orderBy, curYear } = this.state;
    const { userMoments, officialMoment } = await getMoments({ year: curYear, lastItemId, pageSize, orderBy });
    const newUserMoments = refresh ? userMoments : this.state.userMoments.concat(userMoments);
    this.setState({
      userMoments: newUserMoments,
      lastItemId: userMoments[userMoments.length - 1] && userMoments[userMoments.length - 1].id,
      maxPageNum: userMoments.length < pageSize,
    });
    if (!!officialMoment) {
      this.setState({
        officialMoment,
      });
    }

    runInAction(() => {
      this.appStore.userMoments = newUserMoments;
    });
  };

  // 年份切换
  handleActiveYearChange = (year) => {
    document.querySelector('.tab-bar-content').scrollTop = 0;
    this.setState({ curYear: year.value, userMoments: [] }, () => {
      this.fetchMoments();
    });
  };

  renderMomentFilter = () => {
    const { orderBy } = this.state;
    const getCls = (key) => {
      return c('moment-filter-item', {
        'moment-filter-item-active': key === orderBy,
      });
    };
    const handleClickFilter = (key) => {
      if (key !== orderBy) {
        this.setState({ orderBy: key }, () => {
          this.fetchMoments(null, true);
        });
      }
    };
    return (
      <div className="moment-filter">
        <div className={getCls('id')} onClick={() => handleClickFilter('id')}>
          时间
        </div>
        <div className={getCls('likesCount')} onClick={() => handleClickFilter('likesCount')}>
          点赞
        </div>
        <div className={getCls('commentsCount')} onClick={() => handleClickFilter('commentsCount')}>
          评论
        </div>
      </div>
    );
  };

  renderMomentList = () => {
    const { curYear, officialMoment, isReady } = this.state;
    const { forceUpdateUserMoments, userMoments } = this.appStore;
    return (
      <div className="moment-list">
        <Tips />
        <div className="flex">
          <ul className="moment-list-content">
            <OfficialMoment moment={officialMoment} />
            {userMoments.map((momentItem, index) => {
              return <UserMoment key={index} index={index} moment={momentItem} />;
            })}
            <li className="flex justify-center items-center">
              {this.state.maxPageNum ? (
                '- end -'
              ) : (
                <>
                  <Icon type="loading" style={{ marginRight: '5px' }} />
                  正在加载...
                </>
              )}
            </li>
          </ul>
          <div className="timeline-wrap flex-1">{isReady && <Timeline times={this.timeList} onChange={this.handleActiveYearChange} active={curYear} />}</div>
        </div>
        <span style={{ display: 'none' }}>{forceUpdateUserMoments}</span>
      </div>
    );
  };

  render() {
    return (
      <div className="moment-list-page">
        <Search />
        {this.renderMomentFilter()}
        {this.renderMomentList()}
      </div>
    );
  }
}
