/**
 * 游戏挑战 - 组装
 */
import React from 'react';
import c from 'classnames';
import MessageBox from '@comps/MessageBox';
import ScrollContainer from '@comps/ScrollContainer';
import './index.scss';
import { getBuildupTask } from 'api';
import { submitBuildupTask } from 'api/task';
import { getElementPosition, isInside, getElementRound } from '../../utils/dom';

let movingEl = null;
let movingIndex = -1;
let movingData = null;
let gap = {
  x: 0,
  y: 0,
};

function disablePageScroll(e) {
  e.preventDefault();
}
export default class Buildup extends React.Component {
  constructor() {
    super();
    this.targetRef = React.createRef();
    this.resourceEl = React.createRef();
  }

  state = {
    task: {},
    selected: {},
    messageBoxOpts: {
      visible: false,
    },
    candidates: [],
  };

  startAt = 0;

  async componentDidMount() {
    const { buildupId } = this.props.match.params;
    const task = await getBuildupTask(buildupId);
    this.setState({
      task,
      candidates: task.candidates,
    });
    this.startAt = Date.now();
    document.body.addEventListener('touchmove', disablePageScroll, { passive: false });
  }

  componentWillUnmount() {
    document.body.removeEventListener('touchmove', disablePageScroll);
  }

  getMessageBoxOpts(text) {
    return {
      visible: true,
      content: text,
      onClose: () => {
        this.setState({
          messageBoxOpts: {
            ...this.state.messageBoxOpts,
            visible: false,
          },
        });
      },
    };
  }

  handleTouchStart = (e, item) => {
    e.preventDefault();
    const el = e.currentTarget;
    const x = e.touches[0].pageX;
    const y = e.touches[0].pageY;
    const elPos = getElementPosition(el);
    gap = {
      x: elPos.x - x,
      y: elPos.y - y,
    };
    const els = Array.prototype.slice.call(el.parentElement.children, 0);
    const index = els.findIndex((i) => i === el);
    const nextEl = el.nextSibling;
    if (nextEl) {
      const styles = getComputedStyle(el);
      const ml = Number(styles.width.replace(/px/, '')) + Number(styles.marginRight.replace(/px/, ''));
      nextEl.style.marginLeft = ml + 'px';
      nextEl.setAttribute('data-needreset', '1');
    }
    this.setState({
      candidates: this.state.candidates.map((resource, idx) => {
        return idx === index
          ? {
              ...resource,
              moving: idx === index,
              left: elPos.x + 'px',
              top: elPos.y + 'px',
            }
          : resource;
      }),
    });
    movingEl = el;
    movingIndex = index;
    movingData = item;
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    const el = e.currentTarget;
    if (!movingEl || el !== movingEl) return;
    const x = e.nativeEvent.touches[0].pageX;
    const y = e.nativeEvent.touches[0].pageY;
    el.style.left = x + gap.x + 'px';
    el.style.top = y + gap.y + 'px';
  };

  handleTouchEnd = async (e) => {
    e.preventDefault();
    const { task } = this.state;
    const { nw, se } = getElementRound(this.targetRef.current); // 目标左上角
    const width = se.x - nw.x;
    const height = se.y - nw.y;
    const targetPoint = {
      x: nw.x + width * task.coordinate.x,
      y: nw.y + height * task.coordinate.y,
    };
    const targetRound = {
      nw: {
        x: targetPoint.x - 40,
        y: targetPoint.y - 40,
      },
      se: {
        x: targetPoint.x + 40,
        y: targetPoint.y + 40,
      },
    };
    const el = e.currentTarget;
    const styles = getComputedStyle(el);
    const elWidth = Number(styles.width.replace(/px/, ''));
    const elHeight = Number(styles.height.replace(/px/, ''));
    const centerPos = {
      x: Number(el.style.left.replace(/px/, '')) + elWidth / 2,
      y: Number(el.style.top.replace(/px/, '')) + elHeight / 2,
    };
    if (isInside(centerPos, targetRound)) {
      const { answer, id } = this.state.task;
      this.setState({
        selected: movingData,
        candidates: this.state.candidates.map((item) => ({
          ...item,
          moving: false,
          left: null,
          top: null,
        })),
      });
      if (answer === movingData.index) {
        const points = await this.submitAnswer({
          seconds: window.parseInt((Date.now() - this.startAt) / 1000),
          answer,
          buildupId: id,
        });
        this.setState({
          messageBoxOpts: {
            visible: true,
            content: (
              <span>
                恭喜完成挑战
                <br />
                本轮得分 {points} 分
              </span>
            ),
            onClose: () => {
              this.setState({
                messageBoxOpts: {
                  ...this.state.messageBoxOpts,
                  visible: false,
                },
              });
              this.showJumpToAnswerPage();
            },
          },
        });
      } else {
        this.setState({
          messageBoxOpts: this.getMessageBoxOpts('作答有误，请更正'),
        });
      }
    } else {
      this.setState({
        candidates: this.state.candidates.map((item) => ({
          ...item,
          moving: false,
          left: null,
          top: null,
        })),
      });
    }
    const resEls = Array.prototype.slice.call(this.resourceEl.current.querySelectorAll('.build-up-select-item'), 0);
    resEls.forEach((el) => {
      if (el.getAttribute('data-needreset')) {
        el.removeAttribute('data-needreset');
        el.style.marginLeft = 0;
      }
    });
  };

  showJumpToAnswerPage = () => {
    const { buildupId } = this.props.match.params;
    const query = new URLSearchParams(window.location.search);
    this.props.history.replace(`/buildup-answer/buildup/${buildupId}?year=${query.get('year')}`);
  };

  async submitAnswer(data) {
    const { points } = await submitBuildupTask(data);
    return points;
  }

  render() {
    const { messageBoxOpts, task, selected, candidates } = this.state;
    const { background } = task;
    return (
      <div className="build-up">
        <h2>请正确组装一款 J12</h2>
        <div className="build-up-content">
          <div className="build-up-content__container">
            <img src={background} alt="watch" className="watch-bg" ref={this.targetRef} />
            {selected.url ? (
              <img
                src={selected.url}
                alt="select"
                style={{ left: `${task.coordinate.x * 100}%`, top: `${task.coordinate.y * 100}%` }}
                className="selected-bg"
              />
            ) : null}
          </div>
        </div>
        <p className="build-up-tips">
          拖拽放置组件
          <br />
          左右滑动查看更多
        </p>
        <div className="build-up-footer" ref={this.resourceEl}>
          <ScrollContainer>
            {candidates &&
              candidates.map((item) => {
                const candidateCls = c('build-up-select-item', {
                  'build-up-select-item-active': selected.index === item.index,
                  moving: item.moving,
                });
                return (
                  <div
                    key={item.index}
                    className={candidateCls}
                    onTouchStart={(event) => this.handleTouchStart(event, item)}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                    style={{
                      left: item.left,
                      top: item.top,
                    }}
                    onClick={() => {
                      this.handleCandidateClick(item);
                    }}
                  >
                    <img src={item.url} alt="select" className="selected-img" />
                    <p className="selected-text">{item.content}</p>
                  </div>
                );
              })}
          </ScrollContainer>
        </div>
        <MessageBox {...messageBoxOpts} />
      </div>
    );
  }
}
