import React from 'react';
import { SearchBar, List } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import { Avatar } from '@comps/Avatar/index';
import { getUsersWorkWechat } from 'api/user';
import throttle from 'lodash-es/throttle';
@inject('rootStore')
export class Search extends React.Component {
  state = {
    value: '',
    searchValue: '', // 用来提交搜索的确定的值，用于列表高亮展示，防止 throttle 导致value 变化比较频繁，造成不一致的问题
    showResultList: false,
    resultList: [],
    emptyResult: false,
  };

  handleCancel = () => {
    this.setState({
      showResultList: false,
      value: '',
      emptyResult: false,
      resultList: [],
    });
  };

  handleChange = (value) => {
    this.setState({
      value,
    });
    this.handleSearch(value);
  };

  handleSearch = throttle((key) => {
    if (key === '') {
      return;
    }
    this.setState({
      searchValue: key,
    });
    getUsersWorkWechat(key).then((list) => {
      this.setState({
        resultList: list,
        emptyResult: list.length === 0,
      });
    });
  }, 1000);

  renderSearchList = () => {
    const { emptyResult, resultList, searchValue } = this.state;
    const getUserName = (name, key) => {
      // @todo: xss?
      const highlightHtml = `<span class="highlight">${key}</span>`;
      return name.replace(key, highlightHtml);
    };
    if (emptyResult) {
      return (
        <div className="search-result">
          <div className="empty-result-list">暂未找到相关信息</div>
        </div>
      );
    }

    const handleShowUserMomentDialog = (userId) => {
      const store = this.props.rootStore.appStore;
      store.getUserMomentList(userId);
    };

    return (
      <div className="search-result">
        {resultList.map((user, index) => {
          return (
            <div
              className="search-result-item"
              key={index}
              onClick={() => {
                handleShowUserMomentDialog(user.userId);
              }}
            >
              <Avatar size="xs" src={user.avatar} />
              <div className="search-result-item-content">
                <span dangerouslySetInnerHTML={{ __html: getUserName(user.name, searchValue) }} />
                <span className="link">查看</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const { showResultList, value } = this.state;
    return (
      <div className="search-bar">
        <SearchBar
          value={value}
          placeholder="请输入姓名"
          onFocus={() => {
            this.setState({ showResultList: true });
          }}
          onChange={this.handleChange}
          onCancel={this.handleCancel}
        />
        {showResultList ? this.renderSearchList() : null}
      </div>
    );
  }
}
