/**
 *  官方消息
 */

import React, { useEffect } from 'react';
import { Avatar } from '@comps/Avatar';
import { Gallery } from '../Gallery';
import { Comment } from '../Comment';
import { CommentPopup } from './CommentPopup';
import { getCreateTime } from '@utils/time';
import { getOfficialMomentCommentAndLikes } from 'api/index';
import { inject, observer } from 'mobx-react';
import './index.scss';
import { runInAction } from 'mobx';

const OfficialMomentItem = (props) => {
  const { moment } = props;
  const store = props.rootStore.appStore;

  useEffect(() => {
    const { id } = moment;
    if (id) {
      getOfficialMomentCommentAndLikes(id).then(({ likeList, commentList }) => {
        store.updateOfficialLikeList(likeList);
        store.updateOfficialCommentList(commentList);
      });
    }
  }, [moment]);

  const { officialLikeList: likeList, officialCommentList: commentList } = store;

  return (
    <li className="moment-item">
      <Avatar src={moment.avatar} alt="avatar" size="xs" />
      <div className="moment-item-inner">
        <div className="moment-item-title">{moment.year}年</div>
        <div className="moment-item-content">{moment.summary}</div>
        <Gallery list={[{ type: 'IMAGE', url: moment.cover }]} />

        <div className="moment-item-options">
          <span className="moment-create-time">{getCreateTime(moment.createdAt)}</span>

          <CommentPopup id={moment.id} type="official" />
        </div>

        <Comment likeList={likeList} commentList={commentList} maxLine={Infinity} />
      </div>
    </li>
  );
};

const OfficialMoment = inject('rootStore')(observer(OfficialMomentItem));
export { OfficialMoment };
