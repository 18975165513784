import React from 'react';
import classnames from 'classnames';
import throttle from 'lodash-es/throttle';
import './style.scss';

export default class ScrollContainer extends React.Component {
  constructor() {
    super();
    this.scrollEl = React.createRef();
  }

  state = {
    scrollWidth: 0,
    leftDisabled: true,
    rightDisabled: false,
    offset: 0,
  };

  handleScrollRight = () => {
    const scrollWidth = this.scrollEl.current.parentElement.offsetWidth;
    if (this.state.rightDisabled) return;
    this.setState({
      offset: this.state.offset + scrollWidth,
    });
  };

  handleScrollLeft = () => {
    const scrollWidth = this.scrollEl.current.parentElement.offsetWidth;
    if (this.state.leftDisabled) return;
    this.setState({
      offset: this.state.offset - scrollWidth,
    });
  };

  calcDisable = () => {
    const scrollWidth = this.scrollEl.current.parentElement.offsetWidth;
    this.setState({
      rightDisabled: this.scrollEl.current.offsetWidth - this.state.offset - scrollWidth < 0,
      leftDisabled: this.state.offset - scrollWidth <= -scrollWidth,
    });
  };

  componentDidMount() {
    const cb = throttle(this.calcDisable, 200);
    const observer = new MutationObserver(cb);
    observer.observe(this.scrollEl.current, {
      childList: true,
      attributes: true,
      subtree: true,
    });
  }

  render() {
    return (
      <div
        className={classnames('scroll-container', {
          dark: this.props.theme === 'dark',
        })}
      >
        <div
          className={classnames('scroll-container__turn', {
            disabled: this.state.leftDisabled,
          })}
          onClick={this.handleScrollLeft}
        >
          <span className="iconfont iconarrow-left-c"></span>
        </div>
        <div className="scroll-container__main">
          <div className="inner" style={{ left: `-${this.state.offset}px` }} ref={this.scrollEl}>
            {this.props.children}
          </div>
        </div>
        <div
          className={classnames('scroll-container__turn', 'right', {
            disabled: this.state.rightDisabled,
          })}
          onClick={this.handleScrollRight}
        >
          <span className="iconfont iconarrow-left-c"></span>
        </div>
      </div>
    );
  }
}
