import React from 'react';
import { getPointRank } from 'api/rank';
import { RankItem } from '../RankItem';
import { CurUserRankItem } from '../CurUserRankItem';
import { debounce } from 'lodash-es';

export class TotalRankList extends React.Component {
  state = {
    pageNum: 0,
    rankList: [],
    curUserInfo: {},
  };

  scroller = null;

  onScroll = () => {
    const diff = this.scroller.scrollHeight - this.scroller.scrollTop - this.scroller.clientHeight;
    if (diff <= 50) {
      const { pageNum, rankList } = this.state;
      const pageSize = 20;
      if (pageNum + 1 * pageSize > rankList.length) {
        return;
      }
      this.setState({ pageNum: pageNum + 1 });
      this.fetchRankList(pageNum + 1);
    }
  };

  componentDidMount() {
    this.fetchRankList(this.state.pageNum);
    this.initScroller();
  }

  initScroller() {
    const scroller = document.querySelector('.tab-bar-content');
    this.scroller = scroller;
    this.scroller.addEventListener('scroll', debounce(this.onScroll.bind(this), 200), false);
  }

  fetchRankList = async (pageNum = 0) => {
    const { rankList, curUserInfo } = await getPointRank('*', pageNum);
    const contactList = this.state.rankList.concat(rankList);
    this.setState({
      rankList: contactList,
    });
    if (!!curUserInfo) {
      this.setState({
        curUserInfo,
      });
    }
  };

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.onScroll, false);
  }

  render() {
    const { rankList, curUserInfo } = this.state;

    const { position, points, user } = curUserInfo;
    if (!position) {
      return null;
    }
    return (
      <div className="rank-list total-rank-list">
        {/* 我的排名 */}
        <div className="rank-list-fixed-wrapper">
          <CurUserRankItem position={position} points={points} user={user} />
        </div>

        <div className="rank-list-content">
          {rankList.map((item, index) => {
            return <RankItem key={index} userInfo={item.user} position={item.position} points={item.points} />;
          })}
        </div>
      </div>
    );
  }
}
