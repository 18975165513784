/**
 * 用户消息
 */
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import { inject } from 'mobx-react';
import { Avatar } from '@comps/Avatar';
import { Gallery } from '../Gallery';
import { Comment } from '../Comment';
import { CommentPopup } from './CommentPopup';
import { getCreateTime } from '@utils/time';
import { withRouter } from 'react-router-dom';

import { runInAction } from 'mobx';
import './index.scss';
import { removeUserMoment } from 'api/index';

export const InnerUserMoment = withRouter((props) => {
  const { moment, noNeedEllipsis } = props;
  const createTime = getCreateTime(moment.createdAt);
  const store = props.rootStore.appStore;
  const { profile } = props.rootStore.userStore;
  const curUserId = profile.userId;

  const isDetailPage = !!props.isDetailPage;

  // 删除
  const handleDeleteMoment = (id) => {
    removeUserMoment(id).then((success) => {
      if (success) {
        const index = props.index;
        runInAction(() => {
          store.hiddenMessageBox();
          store.removeUserList(index);
        });
        props.history.goBack();
      } else {
        // @todo 异常
      }
    });
  };

  const handleDelete = (id) => {
    store.showMessageBox({
      content: '确认删除？',
      buttonText: '删除',
      onClickButton: handleDeleteMoment.bind(this, id),
    });
  };

  const handleClickAvatar = (userId) => {
    if (isDetailPage) {
      return;
    }
    store.getUserMomentList(userId);
  };

  const handleShowDetailPage = () => {
    runInAction(() => {
      store.showUserMomentDialogDetail = true;
      store.activeOptionMoment = moment;
    });
  };

  const renderContent = () => {
    if (!noNeedEllipsis) {
      return (
        <LinesEllipsis
          className="moment-item-content"
          text={moment.content}
          maxLine="5"
          ellipsis={
            <span>
              ...
              <span className="link" onClick={handleShowDetailPage}>
                全文
              </span>
            </span>
          }
          basedOn="words"
        />
      );
    }
    return <div className="moment-item-content">{moment.content}</div>;
  };

  return (
    <li className="moment-item">
      <Avatar
        src={moment.user.avatar}
        alt={moment.user.name}
        size="xs"
        onClick={() => {
          handleClickAvatar(moment.userId);
        }}
      />
      <div className="moment-item-inner">
        <div
          className="moment-item-title"
          onClick={() => {
            handleClickAvatar(moment.userId);
          }}
        >
          {moment.user.chanelShopName} {moment.user.name}
        </div>
        {renderContent()}
        <Gallery list={moment.media} />

        <div className="moment-item-options">
          <span className="moment-create-time">{createTime}</span>
          {moment.userId === curUserId && (
            <span
              className="moment-delete-button"
              onClick={() => {
                handleDelete(moment.id);
              }}
            >
              删除
            </span>
          )}

          <CommentPopup id={moment.id} type="user" moment={moment} />
        </div>

        <Comment likeList={moment.likes} commentList={moment.comments} onShowDetail={handleShowDetailPage} />
      </div>
    </li>
  );
});

export const UserMoment = inject('rootStore')(InnerUserMoment);
