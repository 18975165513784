/**
 * 用户自己相关的内容
 */
import { client, gql } from './index';

export const getMyWechatWorkProfile = () => {
  return client
    .mutate({
      mutation: gql`
        query demo {
          getMyWechatWorkProfile {
            name
            avatar
            id
            userId
            chanelShopName
          }
        }
      `,
    })
    .then(({ data }) => data.getMyWechatWorkProfile);
};

/**
 * @todo
 * 获取问答挑战是否完成状态
 */
export const getMyQuiz = (puzzleId) => {
  return client
    .mutate({
      mutation: gql`
        mutation($puzzleId: Int!) {
          getMyPuzzle(puzzleId: $puzzleId) {
            puzzleId
          }
        }
      `,
    })
    .then(({ data }) => data.getEnabledOfficialMoments);
};

/**
 * @todo
 * 用于控制游戏挑战是否完成
 */
export const getMyBuildup = (puzzleId) => {
  return client
    .mutate({
      mutation: gql`
        mutation($puzzleId: Int!) {
          getMyPuzzle(puzzleId: $puzzleId) {
            puzzleId
          }
        }
      `,
    })
    .then(({ data }) => data.getEnabledOfficialMoments);
};

/**
 * 获取用户信息
 */
export const getUsersWorkWechat = (keyword) => {
  return client
    .mutate({
      mutation: gql`
        query demo($keyword: String) {
          getUsersWorkWechat(keyword: $keyword) {
            id
            userId
            name
            avatar
            chanelShopName
          }
        }
      `,
      variables: {
        keyword,
      },
    })
    .then(({ data }) => {
      return data.getUsersWorkWechat;
    });
};
