import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import IconFavorite from '@images/icon/favorite.svg';

import './index.scss';

@withRouter
@inject('rootStore')
@observer
export class Comment extends React.Component {
  static defaultProps = {
    maxLine: 8,
  };
  get appStore() {
    return this.props.rootStore.appStore;
  }
  get isDetailPage() {
    return this.appStore.showUserMomentDialogDetail;
  }

  handleShowUserListDialog = (userId) => {
    this.appStore.getUserMomentList(userId);
  };
  handleShowDetail = () => {
    const { onShowDetail } = this.props;
    onShowDetail && onShowDetail();
  };
  renderLikeList = () => {
    const { likeList } = this.props;
    if (!likeList || likeList.length === 0) {
      return null;
    }
    return (
      <div className="moment-likes">
        <ReactSVG src={IconFavorite} wrapper="span" className="favorite-icon" />
        {likeList.map((item, index) => {
          const { user } = item;
          return (
            <span className="user-name" key={index}>
              <span
                onClick={() => {
                  this.handleShowUserListDialog(user.userId);
                }}
              >
                {user.chanelShopName} {user.name}
              </span>

              <span>、</span>
            </span>
          );
        })}
      </div>
    );
  };

  renderCommentList = () => {
    const { commentList, maxLine } = this.props;
    if (!commentList || commentList.length === 0) {
      return null;
    }
    return (
      <div className="moment-comments">
        {commentList.map((comment, index) => {
          const { content, user } = comment;
          const { chanelShopName, name } = user;
          if (index >= maxLine && !this.isDetailPage) {
            return null;
          }
          return (
            <div key={index}>
              <span
                className="user-name"
                onClick={() => {
                  this.handleShowUserListDialog(user.userId);
                }}
              >
                {chanelShopName} {name}
              </span>
              : <span>{content}</span>
            </div>
          );
        })}
        {commentList.length > maxLine && !this.isDetailPage ? (
          <span className="show-more-btn" onClick={this.handleShowDetail}>
            展开
          </span>
        ) : null}
      </div>
    );
  };

  render() {
    // if (Array.isArray(likeList) && likeList.length === 0 && Array.isArray(commentList) && commentList.length === 0) {
    //   return null;
    // }
    return (
      <div className="moment-comment-wrap">
        {this.renderLikeList()}
        {this.renderCommentList()}
      </div>
    );
  }
}
