import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { gql } from 'apollo-boost';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { doAuth } from './auth';

const env = process.env.REACT_APP_ENV || 'stg';

const cropIdMap = {
  dev: 'wwf45fbdd2b31076a4',
  stg: 'wwe8932bd7d8eadb01',
  prod: 'wx52f6fd0ee237027f',
};

const CDNMap = {
  dev: 'https://cdn.dev.dozto.com',
  stg: 'https://cdn.staging.chanel-digital.com',
  prod: 'https://coco-cdn.chanel-digital.com',
};
export const CDN_HOST = CDNMap[process.env.REACT_APP_ENV || 'stg'];

export const cropId = cropIdMap[env];

const gqlHost = {
  dev: 'https://j12-service.dev.dozto.com/graphql',
  stg: 'https://j12-service-stag.chanel-digital.com/graphql',
  prod: 'https://j12-service.chanel-digital.com/graphql',
};

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: gqlHost[env],
  credentials: 'include',
});
const doAuthLink = onError(({ graphQLErrors, networkError }) => {
  window.console.log(networkError);
  window.console.log(graphQLErrors);
  if (graphQLErrors && graphQLErrors[0].status === 401) {
    doAuth();
  }
});

export const client = new ApolloClient({
  link: doAuthLink.concat(httpLink),
  cache,
});
export { gql };

/**
 * 获取最新的官方朋友圈
 * 首页 - 通过enabledAt判断今天是否有新的官方朋友圈，用于控制跳转去不同的页面
 */
export const getLatestOfficialMoment = () => {
  return client
    .mutate({
      mutation: gql`
        query getLatestOfficialMoment {
          getLatestEnabledOfficialMoment {
            title
            summary
            enabledAt
            quizId
            buildupId
            puzzleId
          }
        }
      `,
    })
    .then(({ data }) => data.getLatestEnabledOfficialMoment);
};

export * from './auth';
export * from './moment';
export * from './event';
export * from './user';
export * from './task';
export * from './rank';
