import { observable, action, runInAction } from 'mobx';
import { getEventList, getUserMomentDetail } from 'api/index';
import { mutationCommentOfficialMoment, mutationCommentUserMoment } from 'api/index';

/**
 * app common data
 */
export class AppStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable
  showUserMomentDialogDetail = false;

  @action
  async showDetailAsync(id, year) {
    this.showUserMomentDialogDetail = true;
    getUserMomentDetail(id, year).then((list) => {
      const moment = list[0];
      runInAction(() => {
        this.activeOptionMoment = moment;
      });
    });
  }

  // 用于评论框弹起的时候，记录当前活跃卡片类型
  @observable
  activeMomentItem = {
    type: '',
    id: '',
  };

  @action
  async getUserMomentList(userId) {
    const list = await getUserMomentDetail(userId, '');
    runInAction(() => {
      this.userMomentDialogId = userId;
      this.userMomentDialogList = list;
    });
  }

  // id 不为空的时候，展示 dialog
  @observable
  userMomentDialogId = null;

  @observable
  userMomentDialogList = [];

  @action
  setActiveMomentItem(type, id) {
    this.activeMomentItem = { type, id };
  }

  // 点赞/评论按钮操作展开收起控制
  @observable
  showMomentOptions = false;

  // 评论输入框控制
  @observable
  showMomentTextarea = false;

  @observable
  userMoments = [];

  @observable
  forceUpdateUserMoments = false;

  // 当前操作的活跃用户，详情页展示以及点赞提交等使用
  @observable
  activeOptionMoment = {};

  @observable
  momentMessageBox = {
    visible: false,
    content: '',
    buttonText: '',
    onClickButton() {},
  };

  @action
  showMessageBox({ content, buttonText, onClickButton, extraMessage }) {
    this.momentMessageBox = {
      ...this.momentMessageBox,
      visible: true,
      extraMessage,
      content,
      buttonText,
      onClickButton,
    };
  }

  @action
  removeUserList(index) {
    if (!index) {
      return null;
    }
    this.userMoments.splice(index, 1);
  }

  @action
  hiddenMessageBox() {
    this.momentMessageBox.visible = false;
  }

  @action
  setMomentOptions(show) {
    this.showMomentOptions = show;
  }

  @action
  setShowMomentTextarea(show) {
    this.showMomentTextarea = show;
  }

  @action
  handleShowCommentIconClick() {
    this.showMomentTextarea = true;
    this.showMomentOptions = false;
  }

  @action submitComment(content) {
    const curUserProfile = this.rootStore.userStore.profile;
    const refUserId = curUserProfile.userId;
    const { id, type } = this.activeMomentItem;

    if (!content) {
      return;
    }

    // 官方消息
    if (type === 'official') {
      mutationCommentOfficialMoment(id, content, refUserId).then((success) => {
        if (success) {
          this.updateOfficialCommentList({
            user: curUserProfile,
            officialMomentId: id,
            content,
          });
          this.setShowMomentTextarea(false);
        }
      });
    }
    // 用户信息
    else {
      mutationCommentUserMoment(id, content, refUserId).then((success) => {
        this.setShowMomentTextarea(false);
        let userMoments = this.userMoments.slice();
        if (!!this.userMomentDialogId) {
          userMoments = this.userMomentDialogList;
        }

        runInAction(() => {
          if (userMoments.length > 0) {
            const curMoment = userMoments.find((item) => id === item.id);
            curMoment.comments.push({
              content,
              user: curUserProfile,
            });
          }
          // 详情页面
          if (this.showUserMomentDialogDetail) {
            this.activeOptionMoment.comments.push({
              content,
              user: curUserProfile,
            });
          }
        });
      });
    }
  }

  @observable
  officialLikeList = [];

  @observable
  officialCommentList = [];

  @action
  updateOfficialLikeList(item) {
    this.officialLikeList = item;
  }
  @action
  updateOfficialCommentList(item) {
    if (Array.isArray(item)) {
      this.officialCommentList = item;
    } else {
      this.officialCommentList.push(item);
    }
  }

  /**
   * ============ 对话页 ======
   */

  @observable
  momentsMap = {
    // '2000': {
    //   official: {},
    //   my: {}
    // }
  };

  @action
  setMomentsMap(list, type) {
    if (!Array.isArray(list)) {
      return;
    }
    list.forEach((moment) => {
      const { year } = moment;
      if (!this.momentsMap.hasOwnProperty(year)) {
        this.momentsMap[year] = {};
      }
      this.momentsMap[year][type] = moment;
    });
  }

  @action
  updateMyMomentsMap(year, data) {
    this.momentsMap[year]['my'] = data;
  }

  async fetchEventList() {
    runInAction(() => {
      this.momentsMap = {};
    });
    const data = await getEventList();
    const { officialMoments, myMoments } = data;
    this.setMomentsMap(officialMoments, 'official');
    this.setMomentsMap(myMoments, 'my');
    return data;
  }
}
