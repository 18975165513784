import React from 'react';
import ReactDOM from 'react-dom';
import URLSearchParams from '@ungap/url-search-params';
import 'normalize.css/normalize.css';
import './assets/iconfont.css';
import './index.scss';
import App from './App';
import { code2Token } from './api';
import AuthError from './views/Error/Auth';

async function init() {
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');
  const debug = query.get('debug');
  if (debug) {
    new window.VConsole();
  }
  if (code) {
    try {
      await code2Token(code);
    } catch (e) {
      ReactDOM.render(<AuthError />, document.getElementById('root'));
      throw e;
    }
  }
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

init();
