import React from 'react';
import classnames from 'classnames';
import './style.scss';

export default function Radio({ options = [], isMulti = false, isReadOnly = false, value = null, onChange }) {
  const handleSelect = (option) => {
    if (isReadOnly) return;
    if (isMulti) {
      if (value.indexOf(option.index) !== -1) {
        onChange(value.filter((item) => item !== option.index));
      } else {
        onChange([...value, option.index]);
      }
    } else {
      onChange(option.index);
    }
  };
  const isOptionChecked = (option) => {
    if (isMulti) return value.indexOf(option.index) !== -1;
    return value === option.index;
  };
  return (
    <div className="radio">
      {options.map((option, index) => (
        <div className={classnames('radio-column', {})} key={option.index} onClick={() => handleSelect(option)}>
          <div className={classnames('radio-column__box', { checked: isOptionChecked(option), hasImage: option.contentType === 'IMAGE' })}>
            <div className="radio-column__box--solid"></div>
          </div>
          <div className="radio-column__label">
            {option.contentType === 'IMAGE' ? (
              <img className="radio-column__label--image" src={option.url} alt="图片选项" />
            ) : (
              <>
                {option.index}. {option.content}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
