import React, { useState, useRef, useEffect } from 'react';
import { TextareaItem, Toast } from 'antd-mobile';
import dragula from 'react-dragula';
import { useMount, useMap } from 'react-use';
import MessageBox from '@comps/MessageBox';
import upload, { initWxConfig } from '../../../../utils/mediaUpload';
import { designPx2RealPx } from '../../../../utils';
import { createMoment } from 'api';
import { Button } from '@comps/Button';
import { inject, observer } from 'mobx-react';
import './style.scss';

const EventUpload = ({ rootStore, match, history }) => {
  const { year } = match.params;
  const [medias, setMedias] = useState([]);
  const [canUpload, setCanUpload] = useState(true);
  const [content, setContent] = useState('');
  const [messageBoxOptions, { setAll }] = useMap({
    visible: false,
    content: '确定删除？',
    buttonText: '删除',
    onClose() {
      setAll({
        ...messageBoxOptions,
        visible: false,
      });
    },
  });
  const dragEl = useRef();
  const handleMediaUpload = async () => {
    try {
      // if (medias.length > 0) accepts = ['image', 'image-camera'];
      const files = await upload();
      const afterList = [...medias, ...files];
      setMedias(afterList);
      // if (type === 'video' || afterList.length >= 9) setCanUpload(false);
      if (afterList.length >= 9) setCanUpload(false);
    } catch (e) {
      window.alert(e.message);
    }
  };
  const handleRemoveMedia = (index) => {
    setAll({
      content: '确定删除？',
      visible: true,
      buttonText: '删除',
      onClickButton() {
        setAll({
          ...messageBoxOptions,
          visible: false,
        });
        const afterDelete = medias.filter((item, idx) => idx !== index);
        setMedias(afterDelete);
        // if (afterDelete.length <= 0) setCanUpload(true);
        if (afterDelete.length < 9) setCanUpload(true);
        // if (afterDelete.length < 9 && !afterDelete.find((item) => item.type === 'video')) setCanUpload(true);
      },
    });
  };
  const imgWidth = parseInt(designPx2RealPx(108), 10);
  const handleContentChange = (val) => {
    setContent(val);
  };

  const store = rootStore.appStore;
  const handleSubmit = async () => {
    const data = {
      year,
      content,
      media: medias.map((media) => ({
        ...media,
        type: media.type.toUpperCase(),
      })),
    };
    try {
      await createMoment(data);
      store.updateMyMomentsMap(year, data);
      Toast.success('提交成功');
      history.replace('/events');
    } catch (e) {
      setAll({
        content: '上传失败，稍后再试',
        visible: true,
        onClose() {
          setAll({
            ...messageBoxOptions,
            visible: false,
          });
        },
      });
    }
  };
  function disablePageScroll(e) {
    e.preventDefault();
  }
  useMount(async () => {
    const drag = dragula([dragEl.current], {
      direction: 'horizontal',
      moves(el) {
        if (/events-upload__images--upload/.test(el.className)) return false;
        return true;
      },
      accepts(el, target, source, sibling) {
        if (!sibling && canUpload) return false;
        return true;
      },
    });
    drag.on('dragend', function () {
      const els = Array.prototype.slice.call(dragEl.current.querySelectorAll('.events-upload__images--item'));
      setMedias(
        els.map((el) => ({
          type: el.getAttribute('data-type'),
          url: el.getAttribute('data-url'),
        }))
      );
    });
    await initWxConfig();
  });
  useEffect(() => {
    document.body.addEventListener('touchmove', disablePageScroll, { passive: false });
    return () => {
      document.body.removeEventListener('touchmove', disablePageScroll);
    };
  }, []);
  return (
    <div className="events-upload">
      <div className="events-upload__label">{year}</div>
      <div className="events-upload__title">我的这一年</div>
      <TextareaItem placeholder="写出你的精彩故事…" rows={5} onChange={handleContentChange} />
      <div className="events-upload__images" ref={dragEl}>
        {medias.map((media, index) => (
          <div className="events-upload__images--item" data-url={media.url} data-type={media.type} key={media.url}>
            <div className="delete" onClick={() => handleRemoveMedia(index)}>
              <p className="iconfont iconclose1" />
            </div>
            {media.type === 'image' ? (
              <img src={`${media.url}?x-oss-process=image/resize,w_${imgWidth * 3}`} alt="上传图片" />
            ) : (
              <video controls src={media.url} poster={`${media.url}?x-oss-process=video/snapshot,t_0,f_jpg`} />
            )}
          </div>
        ))}
        {canUpload && (
          <div className="events-upload__images--upload" onClick={handleMediaUpload}>
            <span className="iconfont iconplus"></span>
          </div>
        )}
      </div>
      <div className="events-upload__footer">
        <Button disabled={!content.trim() && medias.length <= 0} onClick={handleSubmit}>
          点击提交
        </Button>
      </div>
      <MessageBox {...messageBoxOptions} />
    </div>
  );
};

export default inject('rootStore')(observer(EventUpload));
