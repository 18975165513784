import React, { useRef } from 'react';
import { useMount } from 'react-use';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import './style.scss';

export default function SwiperComp({ children, options = {}, onChange, onInit }) {
  let swiperInstance = null;
  const swiperEl = useRef();
  useMount(() => {
    swiperInstance = new Swiper(swiperEl.current, {
      ...options,
      init: false,
    });
    if (onChange) {
      swiperInstance.on('slideChange', () => {
        onChange(swiperInstance.activeIndex);
      });
    }
    if (onInit) {
      swiperInstance.on('init', () => {
        onInit(swiperInstance.activeIndex);
      });
    }
    swiperInstance.init();
  });
  return (
    <div className="swiper-container" ref={swiperEl}>
      <div className="swiper-wrapper">
        {children.map((item, index) => (
          <div className="swiper-slide" key={index}>
            {item}
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
}
