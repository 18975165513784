import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { useMount } from 'react-use';
import lottie from 'lottie-web';
import 'react-dragula/dist/dragula.min.css';
import * as Sentry from '@sentry/browser';
import { Home } from './views/Home';
import { Landing } from './views/Landing';
import store from './stores';
import { Main } from './views/Main';
import { EventsDetail } from './views/Main/Events/EventsDetail';
import EventsUpload from './views/Main/Events/EventsUpload';
import Questions from './views/Questions';
import { MomentNews } from 'views/Main/Moment/News/index';
import Puzzle from './views/Puzzle';
import Buildup from 'views/Buildup/index';
import BuildupAnswer from 'views/Buildup/answer';
import { initWxConfig } from './utils/mediaUpload';
import Description from 'views/Description';

Sentry.init({ dsn: 'https://495660095dbe4d6c9bb07efdf2eb0831@o180873.ingest.sentry.io/5222111' });

function App() {
  const loadingRef = useRef();
  const [showLoading, setShowLoading] = useState(false);
  useMount(async () => {
    setShowLoading(false);
    await initWxConfig();
    window.wx.ready(function () {
      window.wx.hideOptionMenu();
    });
  });
  useEffect(() => {
    if (showLoading) {
      const instance = lottie.loadAnimation({
        container: loadingRef.current,
        renderer: 'svg',
        autoplay: true,
        path: '/static/loading/data.json',
      });
      instance.addEventListener('complete', function () {
        setShowLoading(false);
      });
    }
  }, [showLoading]);
  return (
    <div className="App">
      {showLoading && <div className="loading" ref={loadingRef} />}
      <Router>
        <Provider rootStore={store}>
          <Switch>
            <Redirect exact from="/app" to="/app/users" />
            <Route exact path="/" component={Home} />
            <Route exact path="/landing" component={Landing} />
            {/* <Route path="/main" component={Main} /> */}
            <Route path="/questions/:id" component={Questions} />

            <Route path="/events/detail" component={EventsDetail} />
            <Route path="/events/:year/upload" component={EventsUpload} />
            <Route path="/description" component={Description} />
            {/* 对话的几个路由 */}
            <Route path="/events" exact component={Main} />
            <Route path="/moment" exact component={Main} />
            <Route path="/moment/news" exact component={MomentNews} />

            <Route path="/rank" exact component={Main} />
            <Route path="/user" exact component={Main} />

            <Route path="/puzzle/:puzzleId" component={Puzzle} />

            <Route path="/buildup/:buildupId" component={Buildup} />
            <Route path="/buildup-answer/:type/:id" component={BuildupAnswer} />
          </Switch>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
