/**
 * 游戏 - 拼图
 */
import React from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import classnames from 'classnames';
import URLSearchParams from '@ungap/url-search-params';
import MessageBox from '../../components/MessageBox';
import ScrollContainer from '../../components/ScrollContainer';
import { getElementRound, isInside, getElementPosition, findNearest } from '../../utils/dom';
import { getPuzzleTask, submitPuzzleTask } from '../../api';
import './style.scss';

let targetRound, centers, moveingEl, sourceEl, moveingIndex, sourceRound;
let gap = {
  x: 0,
  y: 0,
};

let startAt = 0;
const PuzzleItem = React.memo(({ puzzle, handleTouchStart, handleTouchMove, handleTouchEnd, placeholder }) => {
  return (
    <div
      className={classnames('puzzle-item', {
        moving: puzzle && puzzle.moveing,
        placeholder: placeholder && placeholder.value,
        left: placeholder && placeholder.direction === 'left',
        right: placeholder && placeholder.direction === 'right',
      })}
      style={{
        left: puzzle && puzzle.left,
        top: puzzle && puzzle.top,
      }}
      data-index={puzzle && puzzle.index}
      data-url={puzzle && puzzle.url}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {puzzle && <img src={puzzle.url} alt="puzzle" />}
    </div>
  );
});

function disablePageScroll(e) {
  e.preventDefault();
}

class Puzzle extends React.Component {
  constructor() {
    super();
    this.puzzleEl = React.createRef();
    this.resourceEl = React.createRef();
  }

  state = {
    puzzles: Array(9).fill(null),
    resources: [],
    messageBoxOpts: {
      visible: false,
    },
  };

  getPuzzleEls = () => {
    return Array.prototype.slice.call(this.puzzleEl.current.querySelectorAll('.puzzle-item'));
  };

  getResourceEls = () => {
    return Array.prototype.slice.call(this.resourceEl.current.querySelectorAll('.puzzle-item'));
  };

  handleTouchStart = (e) => {
    e.preventDefault();
    const puzzleEls = this.getPuzzleEls();
    const resourceEls = this.getResourceEls();
    const el = e.currentTarget;
    sourceEl = el.parentNode;
    if (!el.getAttribute('data-url')) return;
    const x = e.touches[0].pageX;
    const y = e.touches[0].pageY;
    const elPos = getElementPosition(el);
    gap = {
      x: elPos.x - x,
      y: elPos.y - y,
    };
    if (sourceEl === this.resourceEl.current) {
      const index = resourceEls.findIndex((item) => item === el);
      const nextEl = resourceEls[index + 1];
      if (nextEl) {
        const styles = getComputedStyle(el);
        const ml = Number(styles.width.replace(/px/, '')) + Number(styles.marginRight.replace(/px/, ''));
        nextEl.style.marginLeft = ml + 'px';
        nextEl.setAttribute('data-needreset', '1');
      }
      this.setState({
        resources: this.state.resources.map((resource, idx) => {
          return idx === index
            ? {
                ...resource,
                moveing: idx === index,
                left: elPos.x + 'px',
                top: elPos.y + 'px',
              }
            : resource;
        }),
      });
    } else {
      const index = puzzleEls.findIndex((item) => item === el);
      moveingIndex = index;
      this.setState({
        puzzles: this.state.puzzles.map((puzzle, idx) => {
          return idx === index
            ? {
                ...puzzle,
                moveing: idx === index,
                left: elPos.x + 'px',
                top: elPos.y + 'px',
              }
            : puzzle;
        }),
      });
    }
    moveingEl = el;
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    const el = e.currentTarget;
    if (!moveingEl || el !== moveingEl) return;
    const x = e.nativeEvent.touches[0].pageX;
    const y = e.nativeEvent.touches[0].pageY;
    el.style.left = x + gap.x + 'px';
    el.style.top = y + gap.y + 'px';
  };

  handleTouchEnd = (e) => {
    e.preventDefault();
    const puzzleEls = this.getPuzzleEls();
    const resourceEls = this.getResourceEls();
    const el = e.currentTarget;
    if (!moveingEl || el !== moveingEl) return;
    const x = e.changedTouches[0].pageX;
    const y = e.changedTouches[0].pageY;
    if (isInside({ x, y }, targetRound)) {
      const { index } = findNearest({ x, y }, centers);
      const p = cloneDeep(this.state.puzzles);
      const r = cloneDeep(this.state.resources);
      if (sourceEl === this.puzzleEl.current) {
        if (puzzleEls[index].getAttribute('data-url')) {
          // 目标位有图，交换
          p[moveingIndex] = p[index];
        } else {
          // 目标位无图，直接放
          p[moveingIndex] = null;
        }
      } else {
        if (puzzleEls[index].getAttribute('data-url')) {
          // 目标位有图，交换
          r.push(p[index]);
        }
        const sourceIndex = resourceEls.findIndex((item) => item === moveingEl);
        this.setState({
          resources: r.filter((resource, idx) => {
            return idx !== sourceIndex;
          }),
        });
      }
      p[index] = {
        url: el.getAttribute('data-url'),
        index: el.getAttribute('data-index'),
      };
      this.setState(
        {
          puzzles: p,
        },
        this.onAfterMove
      );
    } else if (isInside({ x, y }, sourceRound) && sourceEl === this.puzzleEl.current) {
      const p = cloneDeep(this.state.puzzles);
      const index = puzzleEls.findIndex((element) => element === moveingEl);
      p[index] = null;
      const url = el.getAttribute('data-url');
      const idx = el.getAttribute('data-index');
      this.setState({
        puzzles: p,
        resources: [...this.state.resources, { url, index: idx }],
      });
    } else {
      this.setState(
        {
          resources: this.state.resources.map((item) => ({
            ...item,
            moveing: false,
            left: null,
            top: null,
          })),
          puzzles: this.state.puzzles.map((item) => {
            return item
              ? {
                  ...item,
                  moveing: false,
                  left: null,
                  top: null,
                }
              : null;
          }),
        },
        this.onAfterMove
      );
    }
    resourceEls.forEach((item) => {
      if (item.getAttribute('data-needreset')) {
        item.style.marginLeft = '0px';
        item.removeAttribute('data-needreset');
      }
    });
    moveingEl = null;
    sourceEl = null;
    moveingIndex = null;
  };

  onAfterMove = async () => {
    const { match } = this.props;
    const fullfilled = this.state.puzzles.every((puzzle) => puzzle && puzzle.index !== null && puzzle.index !== undefined);
    if (fullfilled) {
      try {
        const { points } = await submitPuzzleTask({
          puzzleId: Number(match.params.puzzleId),
          seconds: window.parseInt((Date.now() - startAt) / 1000),
          answer: this.state.puzzles.map((puzzle) => puzzle.index),
        });
        const that = this;
        this.setState({
          messageBoxOpts: {
            visible: true,
            content: (
              <span>
                恭喜完成挑战
                <br />
                本轮得分 {points} 分
              </span>
            ),
            onClose() {
              that.setState({
                messageBoxOpts: {
                  ...that.state.messageBoxOpts,
                  visible: false,
                },
              });
              const query = new URLSearchParams(window.location.search);
              that.props.history.replace(`/buildup-answer/puzzle/${match.params.puzzleId}?year=${query.get('year')}`);
            },
          },
        });
      } catch (e) {
        const that = this;
        this.setState({
          messageBoxOpts: {
            visible: true,
            content: '作答有误，请更正',
            onClose() {
              that.setState({
                messageBoxOpts: {
                  ...that.state.messageBoxOpts,
                  visible: false,
                },
              });
            },
          },
        });
      }
    }
  };

  placeholder = (index) => {
    if (moveingIndex === null || moveingIndex === undefined) return false;
    if (sourceEl !== this.puzzleEl.current) return false;
    if ((moveingIndex + 1) % 3 === 0) {
      // 最后一列
      return {
        value: index === moveingIndex - 1,
        direction: 'right',
      };
    }
    if (moveingIndex % 3 === 0) {
      // 第一列
      return {
        value: index === moveingIndex + 1,
        direction: 'left',
      };
    }
    if (index === moveingIndex + 1) {
      return {
        value: index === moveingIndex + 1,
        direction: 'left',
      };
    }
    return false;
  };

  async componentDidMount() {
    const { match } = this.props;
    const { candidates } = await getPuzzleTask(Number(match.params.puzzleId));
    this.setState({
      resources: candidates.sort((a, b) => (Math.random() > 0.5 ? 1 : -1)),
    });
    const puzzleEls = this.getPuzzleEls();
    targetRound = getElementRound(this.puzzleEl.current);
    sourceRound = getElementRound(this.resourceEl.current);
    centers = puzzleEls.map((el) => {
      const { x, y } = getElementPosition(el);
      return {
        x: x + el.offsetWidth / 2,
        y: y + el.offsetHeight / 2,
      };
    });
    document.body.addEventListener('touchmove', disablePageScroll, { passive: false });
    startAt = Date.now();
  }

  componentWillUnmount() {
    document.body.removeEventListener('touchmove', disablePageScroll);
  }

  render() {
    const { puzzles, resources, messageBoxOpts } = this.state;
    return (
      <div className="puzzle">
        <div className="puzzle-header">请正确拼出一款 J12</div>
        <div className="puzzle-main">
          <div className="puzzle-container__drag" ref={this.puzzleEl}>
            {puzzles.map((puzzle, index) => {
              return (
                <PuzzleItem
                  key={puzzle ? `${puzzle.index}-${puzzle.url}` : index}
                  puzzle={puzzle}
                  index={index}
                  handleTouchStart={this.handleTouchStart}
                  handleTouchMove={this.handleTouchMove}
                  handleTouchEnd={this.handleTouchEnd}
                  placeholder={this.placeholder(index)}
                />
              );
            })}
          </div>
          <div className="puzzle-container">
            {Array(9)
              .fill(null)
              .map(() => (
                <div className="puzzle-container__item" />
              ))}
          </div>
        </div>
        <div className="puzzle-tips">
          <p>拖拽放置拼图</p>
          <p>左右滑动查看更多</p>
        </div>
        <div className="puzzle-footer">
          <ScrollContainer theme="dark">
            <div className="flex puzzle-footer__resources" ref={this.resourceEl}>
              {resources.map((item) => (
                <div
                  className={classnames('puzzle-item', { moving: item.moveing })}
                  data-url={item.url}
                  data-index={item.index}
                  onTouchStart={this.handleTouchStart}
                  onTouchMove={this.handleTouchMove}
                  onTouchEnd={this.handleTouchEnd}
                  style={{
                    left: item.left,
                    top: item.top,
                  }}
                >
                  <img src={item.url} alt="resource" />
                </div>
              ))}
            </div>
          </ScrollContainer>
        </div>
        <MessageBox {...messageBoxOpts} />
      </div>
    );
  }
}

export default Puzzle;
