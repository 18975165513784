import dayjs from 'dayjs';
/**
 * 获取朋友圈发表时间
 * 当天 显示 上午/下午 hh:mm
 * 前一天的内容 显示 昨天 上午/下午 hh:mm
 * 在前一天之前的 显示 x月x日 上午/下午 hh:mm
 */
export const getCreateTime = (time) => {
  const createTime = dayjs.utc(time).local();
  const formatTime = createTime.format('A hh:mm').replace('AM', '上午').replace('PM', '下午');
  const curTime = dayjs();
  const diffDay = curTime.diff(createTime, 'day');
  if (diffDay > 1) {
    return createTime.format('MM月DD日 ') + formatTime;
  }
  if (diffDay === 1) {
    return '昨天 ' + formatTime;
  }
  return formatTime;
};
