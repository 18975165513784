import { client, gql } from './index';

/**
 * 获取官方足迹，对话列表使用
 */
export const getEventList = () => {
  return client
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        query demo {
          getEnabledOfficialMoments {
            year
            cover
            title
            summary
            sectionTitles
            avatar
            quizId
            buildupId
            puzzleId
            myContent
            myMomentTitle
            myCoverImage
          }
          getMyMoments(pagination: { take: 22 }) {
            year
            content
            userId
            media {
              type
              url
              videoSnapshot
            }
            createdAt
          }

          getMyCompletedQuizzes {
            id: quizId
          }
          getMyCompletedPuzzles {
            id: puzzleId
          }
          getMyCompletedBuildups {
            id: buildupId
          }
        }
      `,
    })
    .then(({ data }) => {
      return {
        officialMoments: data.getEnabledOfficialMoments,
        myMoments: data.getMyMoments,
        completedQuizzes: data.getMyCompletedQuizzes,
        completedPuzzles: data.getMyCompletedPuzzles,
        completedBuildups: data.getMyCompletedBuildups,
      };
    });
};

/**
 * 获取某一年的官方消息
 * 对话阅读详情
 */
export const getOfficialMoment = (year) => {
  return client
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        query($year: String!) {
          getOfficialMoment(year: $year) {
            id
            descriptions {
              type
              url
              section
              content
              thumbnail
              videoSnapshot
              videoSnapshot
            }
            quizId
            buildupId
            puzzleId
          }
        }
      `,
      variables: {
        year,
      },
    })
    .then(({ data }) => data.getOfficialMoment);
};

/**
 * 获取题目完成信息
 */
export const getFinishedStatus = ({ quizId, buildupId, puzzleId }) => {
  let queryList = [];
  let paramList = [];
  if (quizId) {
    queryList.push(`getMyQuiz(quizId: $quizId) { id }`);
    paramList.push('$quizId: Int!');
  }
  if (buildupId) {
    queryList.push(`getMyBuildup(buildupId: $buildupId) { id }`);
    paramList.push('$buildupId: Int!');
  }
  if (puzzleId) {
    queryList.push(`getMyPuzzle(puzzleId: $puzzleId) { id }`);
    paramList.push('$puzzleId: Int!');
  }
  const param = paramList.join(', ');
  return client
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        query demo(${param}) {
          ${queryList.join('\n')}
        }
      `,
      variables: { quizId, buildupId, puzzleId },
    })
    .then(({ data }) => {
      return {
        finishedQuiz: data.getMyQuiz,
        finishedBuildup: data.getMyBuildup,
        finishedPuzzle: data.getMyPuzzle,
      };
    });
};

/**
 * 创建朋友圈
 */
export const createMoment = (data) => {
  return client.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation createMoment($data: CreateUserMomentInput!) {
        createUserMoment(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
};
