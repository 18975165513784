import { client, gql, cropId } from './index';

export const getWechatSign = () => {
  return client
    .query({
      query: gql`
        query($url: String!) {
          getWechatWorkSignature(url: $url) {
            signature
            timestamp
            nonceStr
          }
        }
      `,
      variables: {
        url: window.location.href,
      },
    })
    .then(({ data }) => data.getWechatWorkSignature);
};

export const doAuth = () => {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${cropId}&redirect_uri=${window.location.href}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
};

export const code2Token = (code) => {
  return client
    .mutate({
      mutation: gql`
        mutation($code: String!) {
          loginWithCodeInWorkWechatWebapp(code: $code) {
            token
            userId
          }
        }
      `,
      variables: {
        code,
      },
    })
    .then(({ data }) => {
      window.console.log(data);
    });
};
