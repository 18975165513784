import React from 'react';
import c from 'classnames';
import { TotalRankList } from './TotalRankList/index';
import { BeforeRankList } from './BeforeRankList/index';

import './index.scss';
import { RankSearch } from './RankSearch';

export class Rank extends React.Component {
  state = {
    curTab: 'total', // before | total
  };

  getTabClassName(key) {
    const { curTab } = this.state;
    return c('rank-header-item', {
      'rank-header-item-active': curTab === key,
    });
  }

  renderRankList = () => {
    const { curTab } = this.state;
    if (curTab === 'before') {
      return <BeforeRankList />;
    }
    return <TotalRankList />;
  };

  render() {
    return (
      <div className="rank">
        <div className="rank-header">
          <RankSearch />
          <div
            className={this.getTabClassName('total')}
            onClick={() => {
              this.setState({ curTab: 'total' });
            }}
          >
            <span>总排行</span>
          </div>
          <div
            className={this.getTabClassName('before')}
            onClick={() => {
              this.setState({ curTab: 'before' });
            }}
          >
            <span>往期排行</span>
          </div>
        </div>
        {this.renderRankList()}
      </div>
    );
  }
}
