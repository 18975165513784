import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ReactSVG } from 'react-svg';
import IconArrowRight from '@images/icon/arrow-right.svg';
import { getNewNotificationCount } from 'api/index';

@withRouter
@inject('rootStore')
@observer
export class Tips extends React.Component {
  state = {
    count: 0,
  };
  handleTipsClick = () => {
    this.props.history.push('/moment/news');
  };
  async componentDidMount() {
    const count = await getNewNotificationCount();
    this.setState({ count });
  }
  render() {
    const { count } = this.state;
    const tipsText = count === 0 ? '您没有任何消息' : `你有 ${count} 条新消息`;
    return (
      <div className="moment-list-tips" onClick={this.handleTipsClick}>
        <span className="moment-list-tips-content">
          {tipsText}
          <ReactSVG src={IconArrowRight} className="icon-arrow-right" />
        </span>
      </div>
    );
  }
}
