/**
 *  一些隐藏的需要状态控制东西
 */
import React from 'react';
import c from 'classnames';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// import { MomentList } from './MomentList/index';
import { TextareaItem } from 'antd-mobile';
import MessageBox from '@comps/MessageBox';
import { ReactSVG } from 'react-svg';
import IconFavorite from '@images/icon/favorite.svg';
import IconComment from '@images/icon/comment.svg';
import { runInAction } from 'mobx';
import { mutationLikeOfficialMoment, mutationLikeUserMoment } from 'api';
import { MomentDetail } from '../Detail/index';

@withRouter
@inject('rootStore')
@observer
export class CommonHidden extends React.Component {
  state = {
    clickFavoriteTime: 0,
  };
  get pathname() {
    return this.props.location.pathname;
  }
  get appStore() {
    return this.props.rootStore.appStore;
  }
  get userStore() {
    return this.props.rootStore.userStore;
  }
  isLike = -1;
  bodyListener = null;

  componentDidMount() {
    this.initClickListener();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.bodyListener);
  }

  initClickListener() {
    this.bodyListener = (e) => {
      const popper = document.querySelector('.options-button-content');
      if (!popper) {
        return;
      }
      const isInPopper = popper.contains(e.target);
      if (!isInPopper) {
        runInAction(() => {
          this.appStore.showMomentOptions = false;
        });
      }
    };
    document.addEventListener('click', this.bodyListener, true);
  }

  handleFavorite = (id, type = 'user') => {
    this.appStore.setMomentOptions(false);
    const curTime = new Date();
    if (curTime - this.state.clickFavoriteTime <= 2000) {
      this.appStore.showMessageBox({
        content: '当前操作过于频繁, 请稍后再试',
        buttonText: null,
      });
      return;
    }
    this.setState({
      clickFavoriteTime: curTime,
    });
    if (type === 'official') {
      this.likeOfficeMoment(id);
    } else {
      this.likeUserMoment(id);
    }
  };

  // 给用户点赞
  likeUserMoment = (id) => {
    const userProfile = this.userStore.profile;
    const { activeOptionMoment, userMomentDialogId } = this.appStore;
    const { likes } = activeOptionMoment;

    const curLikesIndex = likes.findIndex((item) => item.user.userId === userProfile.userId);

    const isLike = curLikesIndex !== -1;

    mutationLikeUserMoment(id, !isLike).then((rsp) => {
      let userMoments = this.appStore.userMoments.slice();
      // 详情页面 userMoments 列表为 0
      if (!!userMomentDialogId) {
        userMoments = this.appStore.userMomentDialogList;
      }
      const curMoment = userMoments.length === 0 ? activeOptionMoment : userMoments.find((item) => activeOptionMoment.id === item.id);
      runInAction(() => {
        const likesUserInfo = {
          user: {
            name: userProfile.name,
            userId: userProfile.userId,
            chanelShopName: userProfile.chanelShopName,
          },
        };

        // 已经点赞，从数组中移除
        if (isLike) {
          curMoment.likes.splice(curLikesIndex, 1);
        } else {
          curMoment.likes.push(likesUserInfo);
        }

        this.appStore.activeOptionMoment = curMoment;
        this.appStore.forceUpdateUserMoments = Math.random();
      });
    });
  };

  // 给官方点赞
  likeOfficeMoment = (id) => {
    const userProfile = this.userStore.profile;
    const isLike = this.appStore.officialLikeList.find((item) => item.user.id === userProfile.id);

    mutationLikeOfficialMoment(id, !isLike).then((rsp) => {
      if (rsp) {
        runInAction(() => {
          const likeItem = {
            user: userProfile,
          };

          if (!isLike) {
            // 点赞
            this.appStore.officialLikeList.push(likeItem);
          } else {
            // 取消点赞
            const matchLikeItem = this.appStore.officialLikeList.find((item) => item.user.id === this.userStore.profile.id);
            this.appStore.officialLikeList.remove(matchLikeItem);
          }
        });
      }
    });
  };

  // 展示评论框
  handleShowCommentTextarea = () => {
    this.textareaRef.focus();
    this.textareaRef.state.value = '';
    this.appStore.handleShowCommentIconClick();
  };

  // 提交评论
  handleSubmitComment = () => {
    const content = this.textareaRef.state.value.trim();
    this.appStore.submitComment(content);
  };

  handleMessageClose = () => {
    this.appStore.hiddenMessageBox();
  };
  handleInputBlur = () => {
    setTimeout(() => {
      this.appStore.setShowMomentTextarea(false);
    }, 500);
  };
  render() {
    const { showMomentTextarea, showMomentOptions, momentMessageBox, activeOptionMoment, userMomentDialogId } = this.appStore;
    const showDetailPage = !!this.appStore.showUserMomentDialogDetail;
    if (!showDetailPage && typeof showDetailPage != 'undefined' && showDetailPage != 0) {
      return null;
    }

    const inputWrap = c('moment-input-wrap', {
      'moment-input-visible': showMomentTextarea,
    });

    const commentPopperStyleRight = showDetailPage || userMomentDialogId ? 55 : 116;
    return (
      <>
        {/* 底部输入框 */}
        <div className={inputWrap}>
          <TextareaItem autoHeight className="moment-input" onBlur={this.handleInputBlur} ref={(node) => (this.textareaRef = node)} id="moment-textarea" />
          <button className="btn-moment-comment-submit" type="submit" onClick={this.handleSubmitComment}>
            发送
          </button>
        </div>

        {/* 点赞和评论弹窗 */}
        {showMomentOptions ? (
          <div className="options-button-content" style={{ top: showMomentOptions, right: commentPopperStyleRight }}>
            <span
              onClick={() => {
                this.handleFavorite(activeOptionMoment.id, activeOptionMoment.type);
              }}
            >
              <ReactSVG className="icon" src={IconFavorite} wrapper="span" />赞
            </span>
            <span onClick={this.handleShowCommentTextarea}>
              <ReactSVG className="icon" src={IconComment} wrapper="span" />
              评论
            </span>
          </div>
        ) : null}

        {/* 提示框 */}
        <MessageBox
          visible={momentMessageBox.visible}
          content={momentMessageBox.content}
          buttonText={momentMessageBox.buttonText}
          onClose={this.handleMessageClose}
          onClickButton={momentMessageBox.onClickButton}
        />

        <MomentDetail />
      </>
    );
  }
}
