import React from 'react';
import classnames from 'classnames';
import Swiper from '../Swiper';
import './style.scss';

export default class SwiperRuler extends React.Component {
  constructor() {
    super();
    this.activeBorderRef = React.createRef();
  }

  state = {
    swiperOptions: {
      // freeMode: true,
      slidesPerView: 6,
      centeredSlides: true,
    },
  };

  componentDidMount() {
    if (this.props.direction === 'vertical') {
      const activeEl = this.activeBorderRef.current;
      const wrapper = activeEl.parentElement;
      const slidEl = wrapper.querySelector('.swiper-slide');
      activeEl.style.height = window.getComputedStyle(slidEl).height;
    }
  }

  handleSlideChange = (activeIndex) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.items[activeIndex]);
    }
  };

  render() {
    let { items, direction, initialSlide } = this.props;
    direction = direction || 'horizontal';
    const swiperOptions = {
      ...this.state.swiperOptions,
      direction,
      initialSlide: initialSlide || 0,
    };
    return (
      <div
        className={classnames('swiper-ruler', {
          vertical: direction === 'vertical',
        })}
      >
        {direction === 'vertical' && <div className="active-item-border" ref={this.activeBorderRef}></div>}
        <Swiper options={swiperOptions} onChange={this.handleSlideChange}>
          {items.map((item, index) => {
            return (
              <div key={index} className="swiper-ruler__item">
                {item.label}
              </div>
            );
          })}
        </Swiper>
      </div>
    );
  }
}
