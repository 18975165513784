import React from 'react';
import { inject, observer } from 'mobx-react';
import { UserMoment } from '../MomentList/UserMomentItem';
import './index.scss';
import { runInAction, toJS } from 'mobx';
import { Dialog } from '@comps/Diadog/index';

@inject('rootStore')
@observer
export class MomentDetail extends React.Component {
  get userStore() {
    return this.props.rootStore.userStore;
  }
  get appStore() {
    return this.props.rootStore.appStore;
  }

  handleCancel = () => {
    runInAction(() => {
      this.appStore.showUserMomentDialogDetail = false;
      // this.appStore.activeOptionMoment = {};
    });
  };

  render() {
    const { showUserMomentDialogDetail, activeOptionMoment } = this.appStore;
    const moment = toJS(activeOptionMoment);

    if (!showUserMomentDialogDetail || !moment || Object.keys(moment).length === 0) {
      return null;
    }

    return (
      <Dialog onCancel={this.handleCancel}>
        <div className="moment-detail">
          <UserMoment moment={moment} noNeedEllipsis={true} isDetailPage={true} />
        </div>
      </Dialog>
    );
  }
}
