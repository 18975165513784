/**
 * 用户列表弹窗
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { UserMoment } from '../Main/Moment/MomentList/UserMomentItem';
import './index.scss';
import { runInAction } from 'mobx';
import { Dialog } from '@comps/Diadog/index';

@inject('rootStore')
@observer
export class UserMomentDialog extends React.Component {
  state = {};

  get appStore() {
    return this.props.rootStore.appStore;
  }
  handleCloseDialog = () => {
    runInAction(() => {
      this.props.rootStore.appStore.userMomentDialogId = null;
    });
  };
  handleDialogScroll = () => {
    runInAction(() => {
      this.appStore.showMomentOptions = false;
    });
  };

  render() {
    const { userMomentDialogList, userMomentDialogId } = this.appStore;
    const visible = userMomentDialogId;
    if (!userMomentDialogId) {
      return null;
    }

    return (
      <Dialog className="user-list-dialog" visible={visible} onCancel={this.handleCloseDialog} onScroll={this.handleDialogScroll}>
        {userMomentDialogList.map((moment, index) => {
          return (
            <div key={index}>
              <div className="user-item-title">
                <span>{moment.year}</span>
              </div>
              <UserMoment moment={moment} />
            </div>
          );
        })}
      </Dialog>
    );
  }
}
