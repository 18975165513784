import React from 'react';
import { px2vw } from '../../../../utils';
import './style.scss';

export default function Input({ value, length, isReadOnly = false, onChange }) {
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  const calcWidth = () => {
    if (typeof length !== 'number') return '';
    return px2vw(30 * length) + 'vw';
  };

  const width = calcWidth();

  return <input type="text" readOnly={isReadOnly} value={value} onChange={handleInputChange} style={{ width }} />;
}
