export const getElementPosition = (element) => {
  const { left, top } = element.getBoundingClientRect();
  return {
    x: left,
    y: top,
  };
};

// 获取元素在屏幕上的绝对范围
export const getElementRound = (element) => {
  const { x, y } = getElementPosition(element);
  return {
    nw: {
      x,
      y,
    },
    se: {
      x: x + element.offsetWidth,
      y: y + element.offsetHeight,
    },
  };
};

export const isInside = (currentPos, round) => {
  return currentPos.x > round.nw.x && currentPos.x < round.se.x && currentPos.y > round.nw.y && currentPos.y < round.se.y;
};

export const getDistance = (pos1, pos2) => {
  const xGap = pos1.x - pos2.x;
  const yGap = pos1.y - pos2.y;

  return Math.sqrt(xGap * xGap + yGap * yGap);
};

export const findNearest = (position, list) => {
  const disArr = list.map((item) => getDistance(position, item));
  const min = Math.min(...disArr);
  const index = disArr.indexOf(min);
  return {
    index,
    pos: list[index],
  };
};

export const getElementTop = (el, targetEl) => {
  const parent = el.offsetParent;
  const top = el.offsetTop;
  return parent && parent !== targetEl ? getElementTop(parent, targetEl) + top : top;
};
