import React from 'react';
import c from 'classnames';
import './index.scss';

export class Dialog extends React.Component {
  static defaultProps = {
    visible: true,
  };

  handleCloseDialog = () => {
    this.props.onCancel && this.props.onCancel();
  };
  handleContentScroll = () => {
    this.props.onScroll && this.props.onScroll();
  };
  render() {
    const { visible, content, children, className } = this.props;
    if (!visible) {
      return null;
    }

    return (
      <div className={c('comp-dialog', className)}>
        <div className="comp-dialog-mask" onClick={this.handleCloseDialog}>
          <span className="iconfont iconclose" />
        </div>
        <div className="comp-dialog-content" onScroll={this.handleContentScroll}>
          {content || children}
        </div>
      </div>
    );
  }
}
