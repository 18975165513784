/**
 * 游戏挑战 - 组装 - 360
 */
import React from 'react';
import URLSearchParams from '@ungap/url-search-params';
import 'js-cloudimage-360-view';
import { getBuildupTask, getPuzzleTask } from '../../api/task';
import IconAR from './ar.png';
import { Button } from '@comps/Button';

export default class BuildupAnswer extends React.Component {
  state = {
    picIndex: 0,
    pics: [],
  };
  componentDidMount() {
    const { match } = this.props;
    const { type, id } = match.params;
    this.load({ type, id });
  }

  load = async ({ type, id }) => {
    let action = () => {};
    switch (type) {
      case 'buildup':
        action = getBuildupTask;
        break;
      case 'puzzle':
        action = getPuzzleTask;
        break;
      default:
        action = () => {};
    }
    const { fakeModelImages } = await action(Number(id));
    this.setState(
      {
        pics: fakeModelImages,
      },
      () => {
        setTimeout(() => {
          window.CI360.init();
        }, 0);
      }
    );
  };

  jumpToBuildUpPage = () => {
    const query = new URLSearchParams(window.location.search);
    this.props.history.replace(`/events/detail?year=${query.get('year')}`);
  };

  render() {
    return (
      <div className="build-up-answer">
        <div className="watch-detail cloudimage-360" data-amount={this.state.pics.length} data-image-list={JSON.stringify(this.state.pics)}></div>
        <div className="build-up-answer-footer">
          <div className="icon-ar">
            <img src={IconAR} alt="icon-ar" />
          </div>

          <Button onClick={this.jumpToBuildUpPage}>完成</Button>
        </div>
      </div>
    );
  }
}
