import React from 'react';
import c from 'classnames';
import { SearchBar } from 'antd-mobile';
import { inject } from 'mobx-react';
import throttle from 'lodash-es/throttle';
import { getKeyWordPointRank } from 'api/rank';
import { RankItem } from './RankItem';

@inject('rootStore')
export class RankSearch extends React.Component {
  state = {
    value: '',
    focus: false,
    resultList: [],
    emptyResult: false,
  };

  handleCancel = () => {
    this.setState({
      focus: false,
      value: '',
      emptyResult: false,
      resultList: [],
    });
  };

  handleChange = (value) => {
    this.setState({
      value,
    });
    this.handleSearch(value);
  };

  handleSearch = throttle((key) => {
    if (key === '') {
      return;
    }
    getKeyWordPointRank(key).then((list) => {
      this.setState({
        resultList: list,
        emptyResult: list.length === 0,
      });
    });
  }, 1000);

  renderSearchList = () => {
    const { emptyResult, resultList } = this.state;
    if (emptyResult) {
      return (
        <div className="search-result rank-search-result">
          <div className="empty-result-list">暂未找到相关信息</div>
        </div>
      );
    }

    return (
      <div className="search-result rank-search-result">
        {resultList.map((item, index) => {
          return <RankItem key={index} userInfo={item.user} position={item.position} points={item.points} />;
        })}
      </div>
    );
  };

  render() {
    const { focus, value } = this.state;
    const cls = c('search-bar', {
      'search-bar-focus': focus,
    });
    return (
      <div className={cls}>
        <SearchBar
          value={value}
          placeholder="查找"
          onFocus={() => {
            this.setState({ focus: true });
          }}
          onChange={this.handleChange}
          onCancel={this.handleCancel}
        />
        {focus ? this.renderSearchList() : null}
      </div>
    );
  }
}
