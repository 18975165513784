import React, { useRef } from 'react';
import c from 'classnames';
import { useMount } from 'react-use';
import './ index.scss';
import Swiper from '../../../../components/Swiper';
import { designPx2RealPx } from '../../../../utils';

const Image = ({ src, className }) => {
  const el = useRef();
  useMount(() => {
    el.current.onload = function () {
      const { width } = this;
      const screenWidth = window.outerWidth;
      this.style.width = width < screenWidth ? width : screenWidth;
    };
  });

  return <img src={src} className={className} alt="img-alt" ref={el} />;
};

export class Gallery extends React.Component {
  constructor() {
    super();
    this.previewRef = React.createRef();
  }

  state = {
    swiper: {
      visible: false,
      currentIndex: 0,
      config: {
        pagination: { el: '.swiper-pagination' },
      },
    },
  };

  handleClickImage() {
    window.history.back();
  }

  handleClickGallery(index) {
    window.history.pushState({ view: 'gallery' }, 'gallery', '?view=gallery');
    const popstateHandler = () => {
      this.setState({
        swiper: {
          ...this.state.swiper,
          visible: false,
        },
      });
      window.removeEventListener('popstate', popstateHandler);
    };
    window.addEventListener('popstate', popstateHandler);
    this.setState({
      swiper: {
        ...this.state.swiper,
        visible: true,
        currentIndex: index,
        config: {
          ...this.state.swiper.config,
          initialSlide: index,
        },
      },
    });
  }

  handleSwiperChange = (index) => {
    const activeEl = Array.prototype.slice.call(this.previewRef.current.querySelectorAll('.swiper-slide'), 0)[index];
    const videoEl = activeEl.querySelector('video');
    if (videoEl) {
      videoEl.play();
    }
  };

  handleSwiperInit = (index) => {
    const activeEl = Array.prototype.slice.call(this.previewRef.current.querySelectorAll('.swiper-slide'), 0)[index];
    const videoEl = activeEl.querySelector('video');
    if (videoEl) {
      videoEl.play();
    }
  };

  render() {
    const { list, className } = this.props;
    if (!list) {
      return null;
    }
    const listLen = list.length;
    const cls = c(
      'moment-gallery',
      {
        'moment-gallery-single': listLen === 1,
        'moment-gallery-rect': listLen === 4,
      },
      className
    );
    return (
      <div>
        <div className={cls}>
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className="gallery-item"
                style={{
                  backgroundImage: item.type === 'IMAGE' ? `url(${item.url}?x-oss-process=image/resize,w_600)` : '',
                }}
                onClick={(_e) => this.handleClickGallery(index)}
              >
                {item.type === 'VIDEO' && (
                  <video
                    onClick={(_e) => this.handleClickGallery(index)}
                    playsInline
                    webkit-plays-inline="true"
                    preload="true"
                    x5-video-player-type="h5"
                    x5-video-player-fullscreen="false"
                    x5-playsinline="true"
                    src={item.url}
                    autoPlay={true}
                    muted={true}
                    poster={`${item.url}?x-oss-process=video/snapshot,t_0,f_jpg`}
                  />
                )}
              </div>
            );
          })}
        </div>
        {this.state.swiper.visible && (
          <div className="gallery-preview" ref={this.previewRef}>
            <Swiper options={this.state.swiper.config} onChange={this.handleSwiperChange} onInit={this.handleSwiperInit}>
              {list.map((item, index) => (
                <div className="gallery-preview__img--wrap" onClick={this.handleClickImage} key={index}>
                  {item.type === 'IMAGE' && <Image className="gallery-preview__img" src={item.url} alt="gallery-item" />}
                  {item.type === 'VIDEO' && (
                    <video
                      preload="true"
                      playsInline
                      webkit-plays-inline="true"
                      x5-playsinline="true"
                      autoPlay={true}
                      src={item.url}
                      controls
                      poster={`${item.url}?x-oss-process=video/snapshot,t_0,f_jpg`}
                    />
                  )}
                </div>
              ))}
            </Swiper>
            <div className="gallery-preview__shadow" />
          </div>
        )}
      </div>
    );
  }
}
