import React from 'react';
import c from 'classnames';
import './index.scss';

/**
 * 头像
 */
export function Avatar(props) {
  const { size, src, alt } = props;
  const cls = c(
    'comp-avatar',
    {
      [`comp-avatar-size-${size}`]: size,
    },
    props.className
  );
  return (
    <div className={cls} onClick={props.onClick}>
      <img src={src} alt={alt} />
    </div>
  );
}
Avatar.defaultProps = {
  /**
   * size: 'xs' | 'sm' | 'md' | 'lg'
   * xs: 50, sm: 54, md: 62, lg: 75
   */
  size: 'md',
  src: '',
  alt: 'avatar',
};
