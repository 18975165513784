import React from 'react';
import classnames from 'classnames';
import { QUESTION_TYPE, QUESTION_TYPE_TEXT } from '../../consts';
import Radio from '../Radio';
import Input from '../Input';
import Swiper from '../../../../components/Swiper';
import './style.scss';

export default function QuestionBuilder({ index, question, onChange, isReadOnly = false }) {
  const handleInputValChange = (val, inputIndex, totalLength) => {
    const value = question.value;
    for (let i = 0; i < totalLength; i++) {
      if (i === inputIndex) value[i] = val;
      else if (!value[i]) {
        value[i] = null;
      }
    }
    onChange(value);
  };
  const buildInputContent = (title) => {
    const arr = title.split(/(\{\{\d}\})/);
    let inputIndex = 0;
    return arr.map((item, index) => {
      const matches = item.match(/\{\{(\d)}\}/);
      if (matches) {
        const idx = inputIndex;
        const el = (
          <Input
            key={`input-${index}`}
            isReadOnly={isReadOnly}
            value={question.value[inputIndex]}
            onChange={(val) => {
              handleInputValChange(val, idx, inputIndex);
            }}
            length={Number(matches[1])}
          />
        );
        inputIndex++;
        return el;
      } else {
        return <span key={`span-${index}`}>{item}</span>;
      }
    });
  };

  return (
    <div className={classnames('question-builder', { invalid: question.invalid })}>
      {(question.type === QUESTION_TYPE.SINGLE_CHOICE || question.type === QUESTION_TYPE.MULTI_CHOICE) && (
        <>
          <div className="quesiton-builder__content">
            {index !== null ? index + 1 + '.' : ''} {question.title}
            {`（${QUESTION_TYPE_TEXT[question.type]}）`}
          </div>
          <Swiper options={{ pagination: { el: '.swiper-pagination' } }}>
            {question.description.map((banner, index) => {
              if (banner.type === 'IMAGE') return <img className="question-img" src={banner.url} alt={`banner-${index}`} key={`banner-${index}`} />;
              return (
                <video
                  poster={banner.url + '?x-oss-process=video/snapshot,t_0,f_jpg'}
                  className="question-img"
                  controls
                  src={banner.url}
                  key={`banner-${index}`}
                />
              );
            })}
          </Swiper>
          <Radio isReadOnly={isReadOnly} options={question.options} isMulti={question.type === 'MULTI_CHOICE'} value={question.value} onChange={onChange} />
        </>
      )}
      {question.type === QUESTION_TYPE.FILL_BLANK && (
        <div className="quesiton-builder__content">
          {index !== null ? index + 1 + '.' : ''} {buildInputContent(question.title)}
          {`（${QUESTION_TYPE_TEXT[question.type]}）`}
        </div>
      )}
    </div>
  );
}
