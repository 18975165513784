/**
 * J12 和我的年鉴
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { EventCard } from '@comps/EventCard/index';
import './index.scss';
import { getUserCover } from '../Events/EventsList/Card';

@withRouter
@inject('rootStore')
@observer
export class UserList extends React.Component {
  get appStore() {
    return this.props.rootStore.appStore;
  }

  state = {
    completedBuildups: [],
    completedQuizzes: [],
    completedPuzzles: [],
  };

  async componentDidMount() {
    const momentsListLen = Object.keys(this.appStore.momentsMap).length;
    if (momentsListLen === 0) {
      const { completedBuildups, completedPuzzles, completedQuizzes } = await this.appStore.fetchEventList();
      this.setState({
        completedBuildups,
        completedQuizzes,
        completedPuzzles,
      });
    }
  }

  render() {
    const momentsMap = this.appStore.momentsMap;
    const momentsList = Object.keys(momentsMap);
    if (momentsList.length === 0) {
      return null;
    }
    return (
      <div>
        {momentsList.map((year) => {
          const { official, my } = momentsMap[year];
          if (!official) {
            return null;
          }
          const userMedia = my && getUserCover(my.media);

          return (
            <div className="personal-preview-item" key={year}>
              <EventCard
                direction="right"
                className="official-preview-card"
                type="preview"
                cover={official.myCoverImage || official.cover}
                summary={official.myMomentTitle || official.summary}
                sectionTitles={official.sectionTitles}
                onContentClick={() => {
                  this.props.history.push(`/events/detail?year=${official.year}`);
                }}
              />
              <div className="personal-preview-item-line">
                <span className="time-line"></span>
                <div className="year-wrap">
                  <span className="year">{year}</span>
                </div>
              </div>
              {my && (
                <EventCard
                  className={!!userMedia ? '' : 'plain-text-card'}
                  type="preview"
                  cover={userMedia}
                  summary={my.content}
                  onClick={() => {
                    this.appStore.showDetailAsync(my.userId, year);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
