import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import typewriterAudio from './typewriter.mp3';
import './index.scss';
import '../../image-sequence/gka.css';

export function Landing() {
  const location = useLocation();

  const { summary, title } = location.query;
  // const summary = 'J12-2014';
  // const title = '尽情期待';
  const titleArr = title.trim().split('');
  const summaryArr = summary.trim().split('');
  const titleEle = titleArr.map((text, index) => <span key={index}>{text}</span>);
  const summaryEle = summaryArr.map((text, index) => <span key={index}>{text}</span>);

  const getStyle = (len) => {
    return {
      width: 26 * len + 'px',
    };
  };
  return (
    <div className="landing-page">
      <div className="landing-title-wrap">
        <div>
          <h1 className="landing-title" style={getStyle(titleArr.length)}>
            {titleEle}
          </h1>
        </div>
        <div>
          <h1 className="landing-title landing-summary" style={{ ...getStyle(summaryArr.length) }}>
            {summaryEle}
          </h1>
        </div>
      </div>

      <div className="landing-typer-image">
        <div className="animation"></div>
      </div>
      <div className="landing-button-wrap">
        <Link to="/events" className="landing-button">
          您有一条新消息
        </Link>
      </div>

      <audio controls="controls" loop="loop" autoPlay="autoplay" className="typewriter-audio">
        <source src={typewriterAudio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}
