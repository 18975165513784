import React from 'react';

import { Avatar } from '@comps/Avatar/index';
import { getRankTitle } from './img/getRankTitle';
import { inject } from 'mobx-react';

export const InnerRankItem = ({ rootStore, userInfo, position, points }) => {
  if (!userInfo) {
    return null;
  }
  const { avatar, chanelShopName, name, userId } = userInfo;
  const handleClickAvatar = () => {
    rootStore.appStore.getUserMomentList(userId);
  };
  return (
    <div className="rank-item" onClick={handleClickAvatar}>
      <div className="rank-icon">{getRankTitle(position)}</div>
      <div className="rank-item-content">
        <div className="rank-item-user-info">
          <Avatar size="sm" src={avatar} />
          <div className="rank-item-profile">
            <div className="rank-item-user-name">{name}</div>
            <div className="rank-item-shop-name">{chanelShopName}</div>
          </div>
        </div>
        <div className="rank-item-score">{points}分</div>
      </div>
    </div>
  );
};

export const RankItem = inject('rootStore')(InnerRankItem);
