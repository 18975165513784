import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { EventsIcon, EventsSelectedIcon, MomentIcon, MomentSelectedIcon, RankIcon, RankSelectedIcon, UserIcon, UserSelectedIcon } from './TabIcon';
import { EventsList } from './Events/EventsList';
import { Rank } from './Rank';
import { UserList } from './User';
import './index.scss';
import { Moment } from './Moment/index';
import classnames from 'classnames';
import { UserMomentDialog } from 'views/UserMomentDialog/index';
import { CommonHidden } from './Moment/Comment/CommonHidden';
import { runInAction } from 'mobx';

@withRouter
@inject('rootStore')
@observer
export class Main extends React.Component {
  tabs = [
    {
      title: '对话',
      key: 'events',
      icon: <EventsIcon />,
      selectedIcon: <EventsSelectedIcon />,
      content: <EventsList />,
    },
    {
      title: '足迹',
      key: 'moment',
      icon: <MomentIcon />,
      selectedIcon: <MomentSelectedIcon />,
      content: <Moment />,
    },
    {
      title: '排行',
      key: 'rank',
      icon: <RankIcon />,
      selectedIcon: <RankSelectedIcon />,
      content: <Rank />,
    },
    {
      title: '我的',
      key: 'user',
      icon: <UserIcon />,
      selectedIcon: <UserSelectedIcon />,
      content: <UserList />,
    },
  ];
  get appStore() {
    return this.props.rootStore.appStore;
  }
  get pathname() {
    return this.props.location.pathname;
  }
  async componentDidMount() {
    await this.props.rootStore.userStore.getMyWechatWorkProfile();
  }
  isActive = (key) => {
    const activeKey = this.pathname.replace('/', '');
    return key === activeKey;
  };
  handleTabClick = (key) => {
    this.props.history.push('/' + key);
  };
  handleTabContentScroll = () => {
    if (this.appStore.showMomentOptions) {
      runInAction(() => {
        this.appStore.showMomentOptions = false;
      });
    }
  };
  render() {
    const curActiveKey = this.pathname.replace('/', '');
    const { showMomentTextarea } = this.appStore;
    const activeContent = this.tabs.filter((item) => {
      return item.key === curActiveKey;
    })[0];

    return (
      <div className="main-page">
        <div className="tab-bar">
          <div className={classnames('tab-bar-content', { 'overflow-hidden': showMomentTextarea })} onScroll={this.handleTabContentScroll}>
            {activeContent.content}
          </div>
          <ul className="tab-bar-nav">
            {this.tabs.map((tab) => {
              return (
                <li className="tab-bar-nav-item" key={tab.key} onClick={this.handleTabClick.bind(this, tab.key)}>
                  {this.isActive(tab.key) ? tab.selectedIcon : tab.icon}
                  <span className="tab-bar-nav-item-title">{tab.title}</span>
                </li>
              );
            })}
          </ul>

          <UserMomentDialog />
          <CommonHidden />
        </div>
      </div>
    );
  }
}
