import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import lottie from 'lottie-web';
import { OfficialCard, MyCard } from './Card';
import './index.scss';

@withRouter
@inject('rootStore')
@observer
export class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.eventListRef = createRef();
    this.loadingRef = createRef();
  }
  state = {
    completedBuildups: [],
    completedQuizzes: [],
    completedPuzzles: [],
    showLoading: true,
  };

  get appStore() {
    return this.props.rootStore.appStore;
  }
  get userStore() {
    return this.props.rootStore.userStore;
  }

  get momentsMap() {
    return toJS(this.appStore.momentsMap);
  }

  async componentDidMount() {
    const instance = lottie.loadAnimation({
      container: this.loadingRef.current,
      renderer: 'svg',
      autoplay: true,
      path: '/static/loading/data.json',
    });
    const { completedBuildups, completedPuzzles, completedQuizzes } = await this.appStore.fetchEventList();
    this.setState({
      completedBuildups,
      completedQuizzes,
      completedPuzzles,
    });
    const imgs = Array.from(this.eventListRef.current.querySelectorAll('img'));
    await Promise.all(imgs.map(this.isImgLoaded));
    this.eventListRef.current.scrollTop = this.eventListRef.current.scrollHeight;
    this.setState({
      showLoading: false,
    });
  }

  isImgLoaded(img) {
    return new Promise((resolve) => {
      img.onload = resolve;
    });
  }

  isFinishedQuiz = (id) => {
    return this.state.completedQuizzes.some((item) => item.id === id);
  };
  isFinishedBuildups = (id) => {
    return this.state.completedBuildups.some((item) => item.id === id);
  };
  isFinishedPuzzle = (id) => {
    return this.state.completedPuzzles.some((item) => item.id === id);
  };

  handleShowDetailPage = (userId, year) => {
    this.appStore.showDetailAsync(userId, year);
  };

  render() {
    const { avatar } = this.userStore.profile;

    return (
      <div className="event-list" ref={this.eventListRef}>
        {this.state.showLoading && <div className="loading" ref={this.loadingRef} />}
        {Object.keys(this.momentsMap).map((year) => {
          const { official, my } = this.momentsMap[year];
          if (!official) {
            return null;
          }
          const userFinishedStatus = {
            game: this.isFinishedBuildups(official.buildupId) || this.isFinishedPuzzle(official.puzzleId),
            quiz: this.isFinishedQuiz(official.quizId),
          };
          return (
            <div key={year}>
              <div className="event-year">{year}年</div>
              <OfficialCard official={official} userFinishedStatus={userFinishedStatus} />
              <MyCard
                avatar={avatar}
                user={my}
                year={year}
                onShowDetailPage={() => {
                  this.handleShowDetailPage(my.userId, year);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
