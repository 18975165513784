import React from 'react';
import c from 'classnames';
import './index.scss';

export function FixedButtonGroup(props) {
  const isSingleButton = !Array.isArray(props.children);
  const content = isSingleButton ? <div className="fixed-single-button-wrap"> {props.children} </div> : props.children;
  const cls = c('fixed-button-group', props.className);
  return <div className={cls}>{content}</div>;
}
