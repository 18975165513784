import React from 'react';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { getElementTop } from '@utils/dom';

@inject('rootStore')
@observer
export class CommentPopup extends React.Component {
  get appStore() {
    return this.props.rootStore.appStore;
  }
  getContainerWrap = () => {
    // 弹窗的容器
    const dialogContainer = document.querySelector('.comp-dialog-content');
    if (dialogContainer) {
      return dialogContainer;
    }
    const momentListContainer = document.querySelector('.tab-bar-content');
    if (momentListContainer) {
      return momentListContainer;
    }
    const momentDetailContainer = document.querySelector('.moment-detail');
    if (momentDetailContainer) {
      return momentDetailContainer;
    }
  };
  handleShowButtonGroups = (e) => {
    const { id, type, moment } = this.props;
    const container = this.getContainerWrap();
    if (!!container) {
      const scrollTop = container.scrollTop;
      const offsetTop = getElementTop(e.target, document.body);
      // 按钮距离顶部的位置
      this.appStore.setMomentOptions(offsetTop - scrollTop - 6);
    }

    this.appStore.setActiveMomentItem(type, id);
    runInAction(() => {
      this.appStore.activeOptionMoment = { id, type, ...moment };
    });
  };
  render() {
    return (
      <div className="options-button">
        <div className="options-button-icon" onClick={this.handleShowButtonGroups} />
      </div>
    );
  }
}
