import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@comps/Button';
import { useInterval } from '@utils/hooks';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

import MemoryImage from '@images/memory-bg.png';
import MinuteHandsImage from '@images/minute-hands.png';
import HourHandsImage from '@images/hour-hands.png';

import './index.scss';
import { getLatestOfficialMoment } from 'api';
import { getFinishedStatus } from 'api/event';

dayjs.extend(utc);

export function Home() {
  const [curMinsDeg, setCurMinsDeg] = useState(null);
  const [curHourDeg, setCurHourDeg] = useState(null);
  const [jumpToLanding, setJumpToLanding] = useState(false);
  const [jumpQuery, setJumpQuery] = useState({});
  const history = useHistory();
  const videoRef = useRef();
  useEffect(() => {
    updateTime();
  }, []);

  useMount(async () => {
    const { title, summary, enabledAt, quizId } = await getLatestOfficialMoment();
    const { finishedQuiz } = await getFinishedStatus({ quizId });
    const isPushDay = dayjs(enabledAt).isSame(dayjs(), 'day');
    // 推送日当天没有完成
    setJumpToLanding(isPushDay && !finishedQuiz);
    setJumpQuery({ title, summary });
    videoRef.current.addEventListener('ended', function () {
      this.classList.add('hidden');
      if ('webkitExitFullScreen' in this) {
        this.webkitExitFullScreen();
      }
      if ('exitFullScreen' in this) {
        this.exitFullScreen();
      }
      this.load();
    });
    videoRef.current.addEventListener('webkitendfullscreen', function () {
      this.pause();
      this.classList.add('hidden');
    });
    videoRef.current.addEventListener('play', function () {
      this.classList.remove('hidden');
    });
    videoRef.current.addEventListener('x5videoexitfullscreen', function () {
      this.pause();
      this.classList.add('hidden');
    });
  });

  // 每 30s 同步本地时间
  useInterval(() => {
    updateTime();
  }, 1000);

  function updateTime() {
    const time = new Date();
    const curMins = time.getMinutes();
    const curMinsDeg = curMins * (360 / 60);
    const curHour = time.getHours();
    const curHourDeg = ((curHour % 12) + curMins / 60) * (360 / 12);
    setCurMinsDeg(curMinsDeg);
    setCurHourDeg(curHourDeg);
  }

  const jumpToNextPage = () => {
    // 当当前所在的一期活动(最新一期) ) 阅读任务和游戏任务都没有完成时，显示打字机页面
    if (jumpToLanding) {
      history.push({
        pathname: '/landing',
        query: jumpQuery,
      });
    } else {
      history.push('/events');
    }
  };

  const handleClickVideo = (e) => {
    e.preventDefault();
    videoRef.current.currentTime = 0.1;
    videoRef.current.play();
  };

  const handleClickDescription = (e) => {
    e.preventDefault();
    history.push('/description');
  };

  return (
    <div className="home-page">
      <div>
        <div className="memory-wrap">
          <img className="memory-bg" src={MemoryImage} alt="memory-bg" />
          <div className="clock">
            <span className="hour-hands" style={{ transform: `rotate(${curHourDeg}deg)` }}>
              <img src={HourHandsImage} alt="hour-hands" />
            </span>
            <span className="minute-hands" style={{ transform: `rotate(${curMinsDeg}deg)` }}>
              <img src={MinuteHandsImage} alt="hour-hands" />
            </span>
          </div>
        </div>
      </div>
      <h1 className="home-title">J12 时光印记</h1>
      <div className="home-btn flex flex-col items-center">
        <Button reverse={true} onClick={jumpToNextPage}>
          进入
        </Button>
        <div className="home-buttons">
          <a className="home-btn__video" href="#" onClick={handleClickVideo}>
            玩法介绍
          </a>
          <a href="#" className="home-btn__video" onClick={handleClickDescription}>
            奖品说明
          </a>
        </div>
        <video
          preload="auto"
          className="hidden"
          controls
          ref={videoRef}
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientatio="portraint"
          src="https://cdn.chanel-digital.com/videos/j12_20_tutorial.mp4"
        />
      </div>
    </div>
  );
}
